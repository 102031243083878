/**
 * Scrolls target to the top
 *
 * @param {boolean} smooth - use smooth scrolling, default: true
 * @param {HTMLElement} target - target to scroll to the top, default to `window` [other values are untested!]
 */
export function scrollToTop(smooth = true, target = window) {
	const scrollOptions = {
		top: 0,
		left: 0,
		smooth: smooth ? 'smooth' : 'auto',
	}
	// Using scrollTo instead of scroll because it has Safari support
	target.scrollTo(scrollOptions)
}

/**
 * Scrollt target to a given element
 *
 * @param {HTMLElement} element - element to scroll to
 * @param {boolean} smooth - use smooth scrolling, default: true
 * @param {HTMLElement} target - target to scroll to the top, default to `window` [other values are untested!]
 */
export function scrollToElement(element, smooth = true, target = window) {
	const scrollOptions = {
		top: element.offsetTop,
		left: 0,
		smooth: smooth ? 'smooth' : 'auto',
	}
	target.scrollTo(scrollOptions)
}

/**
 * Trigger the callback function if the user has scrolled to the end of the scollable object
 *
 * @param {Event} event - Default JS onscroll event object
 * @param {Function} callback - The function that is called if the scroll is on the bottom
 * @param {object|undefined} args - (optional) The arguments for the callback function
 */
export function loadMore(event, callback, args = undefined) {
	const targetElement = event.currentTarget
	if (
		targetElement.offsetHeight + targetElement.scrollTop >=
		targetElement.scrollHeight
	) {
		callback(event, args)
	}
}

/**
 * Checks if an element in visible in the window (viewport)
 *
 * @param {HTMLElement} element - DOM element that is checked
 * @returns {boolean} - Returns true if the element is in the viewpoint, returns false if not
 */
export function isInViewport(element) {
	element = element.getBoundingClientRect()
	return (
		element.top >= 0 &&
		element.left >= 0 &&
		element.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		element.right <= (window.innerWidth || document.documentElement.clientWidth)
	)
}
