/**
 * Defines the plugin that uses our custom upload adapter
 *
 * @param {any} editor - the ckeditor instance
 */
export function EasyUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new EasyUploadAdapter(loader)
	}
}

/**
 * Upload Adapter that will upload images to easyVerein storage
 */
class EasyUploadAdapter {
	constructor(loader) {
		this.loader = loader
	}

	async upload() {
		const file = await this.loader.file
		const formData = new FormData()
		formData.append('upload', file)
		const uploadLink = '/app/api/storage/?action=upload&category=publicFiles'
		const response = await fetch(uploadLink, {
			method: 'POST',
			body: formData,
		})
		const json = await response.json()
		if (json.code === 200) {
			return { default: json.publicLinks[0] }
		} else {
			throw json.msg
		}
	}

	abort() {
		// Noop
	}
}
