import { Datepicker } from 'vanillajs-datepicker'
import { getGlobals } from '../django'
import de from 'vanillajs-datepicker/locales/de'
import es from 'vanillajs-datepicker/locales/es'
import { debounce } from '../debounce'
import { delay } from '../delays'

/**
 * Thin wrapper for the default datepicker
 *
 * @param {string} selector - CSS selector
 * @param {string} container - CSS selector of the element that the picker is attached to (default: body)
 * @param {string} buttonClass - CSS class for <button> elements (default: btn)
 * @param {string} format - date format
 * @returns {Array<object>} list of datepicker instances
 */
export function createDefaultDatepicker(
	selector,
	container = 'body',
	buttonClass = 'btn',
	format = 'dd.mm.yyyy'
) {
	const languageCode = getGlobals().languageCode
	const pickers = []
	const options = {
		buttonClass: buttonClass,
		format: format,
		container: container,
		language: languageCode,
		autohide: true,
	}
	Object.assign(Datepicker.locales, de, es)
	document.querySelectorAll(selector).forEach((element) => {
		pickers.push(new Datepicker(element, options))
		element.addEventListener('change', (event) =>
			checkDateInput(event, event.key)
		)
		element.addEventListener('keyup', (event) =>
			debounce(() => checkDateOnInput(event), delay.long)()
		)
		element.addEventListener('blur', (event) =>
			checkDateInput(event, event.key)
		)
	})
	return pickers
}

/**
 * Calling checkDateInput when no ignoreable key was pressed
 *
 * @param {Event} event - Default JS keyup event object
 */
function checkDateOnInput(event) {
	const ignoreKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Shift']
	if (!ignoreKeys.includes(event.key)) {
		checkDateInput(event, event.key)
	}
}

/**
 * autofill and correct date input fields
 *
 * @param {object} event - Onkeypress or Onchange default JS event object
 * @param {string} key - key pressed
 * @returns {undefined} - Nothing
 */
function checkDateInput(event, key = '') {
	const element = event.srcElement
	if (!element.value) return
	let dateValue = element.value.replace(/[^0-9.]/g, '')
	dateValue = dateValue.split('.')
	let day = dateValue[0] || ''
	let month = dateValue[1] || ''
	let year = dateValue[2] || ''
	if (day.length > 2 || month.length > 2) {
		dateValue = element.value.replace(/\D/g, '')
		day = dateValue.slice(0, 2)
		month = dateValue.slice(2, 4)
		year = dateValue.slice(4, 8)
	}

	month = checkMonth(month, year)
	day = checkDay(day)
	year = checkYear(year)

	if (key === '.') {
		if (month.length === 1) {
			month = '0' + month
		} else if (day.length === 1) {
			day = '0' + day
		}
	}

	// exceptions
	if (year.length === 4) {
		const exceptions = ['dateOfBirth'] // exceptions cannot be in the future
		let newYear = Number.parseInt('20' + year.slice(-2))

		if (newYear > new Date().getFullYear() && exceptions.includes(element.id)) {
			newYear = Number.parseInt('19' + year.slice(-2))
			year = newYear
		}
	}
	if (dateValue[0] === '') {
		element.value = ''
	} else if (year !== '' || month.length === 2) {
		element.value = [day, month, year].join('.')
	} else if (day.length === 2) {
		element.value = [day, month].join('.')
	}
}

/**
 * Corrects the year of the Datepicker
 *
 * @param {string} year - The year as string
 * @returns {string} - The corrected year
 */
function checkYear(year) {
	if (year !== '') {
		if (year < 1900) {
			year = '19' + year.slice(-2)
		} else if (year > 2099) {
			year = '2099'
		}
	}

	return year
}

/**
 * Corrects the day of the Datepicker
 *
 * @param {string} day - The number of the day in the month
 * @returns {string} - The corrected day
 */
function checkDay(day) {
	if (day !== '') {
		if (day[0] > '3') {
			day = '0' + day[0]
		} else if (day[0] === '3' && day[1] > '1') {
			day = '31'
		} else if (day[0] === '0' && day[1] === '0') {
			day = '01'
		}
	}

	return day
}

/**
 * Corrects the month for the Datepicker
 *
 * @param {string} month - The number of the month as string
 * @param {string} year - The year as string
 * @returns {string} - The corrected month
 */
function checkMonth(month, year) {
	if (month === '') {
		if (year !== '') month = '01'
	} else {
		if (month[0] > '1') {
			month = '0' + month[0]
		} else if (month[0] === '1' && month[1] > '2') {
			month = '12'
		} else if (month[0] === '0' && month[1] === '0') {
			month = '01'
		}
	}

	return month
}
