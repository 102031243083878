import { gettext } from '../utils/translation'
import { VERSION as API_VERSION } from '../api/crud'
import { escapeHtml } from '../utils/html'
import { setElementVisibility } from '../utils/helpers/show'
import { setLoadingCircle } from '../utils/loader.mjs'
import { debounce } from '../utils/debounce'
import { delay } from '../utils/delays'
import { getContextData } from '../utils/django'

/**
 * Usage: If this file is included you can just add `<list-builder></list-builder>` in the HTML
 *
 * To configure the `<list-builder>` use the following attributes:
 *
 * Important:
 * - `endpoint="{API-ENDPOINT-NAME}"` -- API Endpoint, this also implies how entries are rendered.
 * - `use-local-data` -- Only use this if want to use data that isn't fetched from the API. You then need to use `setData` and use what would be the `endpoint` as the `dataType` argument. If this attribute is not set and you use local data, the list-builder will still send requests which will probably result in errors.
 *
 * Recommended:
 * - `endpoint-query="{QUERY}"` -- Query filter like "{id,org{short}}" etc.
 * - `endpoint-filter="{FILTER}"` -- Query-parameter filter. Applied to both querys.
 * - `endpoint-options-fiter="{FILTER}"` -- Query-parameter filter that is only added to the query of the options side.
 * - `endpoint-selection-filter="{FILTER}"` -- Query-parameter filter that is only added to the query of the selection side.
 *
 * - `options-title="{STRING}"` -- Title of the left selection box
 * - `selection-title="{STRING}"` -- Title of the right selection box
 * - `options-search-help="{STRING}"` -- Placeholder of the left search box
 * - `selection-search-help="{STRING}"` -- Placeholder of the right search box
 *
 * Optional:
 * - `sortable-fields="{JSON}"` -- Take a JSON string of an array of `{key, label}` objects. `key` is the field name and `label` is the name the frond end shows for it. Adding a `-` infront of the key will invert the order. If this attribut is not given, sorting is disabled and will just retrieve data in ID order.
 * - `options-default-order="{KEY}"` -- Sets the default sorting of the left selection box. `{KEY}` needs to be in `sortable-fields`.
 * - `selection-default-order="{KEY}"` -- Sets the default sorting of the right selection box. `{KEY}` needs to be in `sortable-fields`.
 *
 * - `options-disabled` -- Disables clicking the items that are not selected. Doesn't need a value.
 * - `selection-disabled` -- Disables clicking the items that are selected. Doesn't need a value.
 * - `options-search-disabled` -- Dissables and hides the search on the left selection box. Doesn't need a value.
 * - `selection-search-disabled` -- Disables and hides the search on the right selection box. Doesn't need a value.
 */
class ListBuilder extends HTMLElement {
	/**
	 * Specify observed attributes so that `attributeChangedCallback` will work
	 *
	 * @returns {Array<string>} list of observed attributes
	 */
	static get obvservedAttributes() {
		return ['selection-disabled', 'options-disabled']
	}

	constructor() {
		super()
		/** Maps PK to object data */
		this.data = {}
		/**
		 * @type {Array<number>} List of pks of non-selected data objects
		 */
		this.options = []
		/** @type {Array<number>} List of pks of selected data object */
		this.selection = []
		/** @type {object} Maps selection changes as PK: true/false. True means newly selected, false means deselected */
		this.selected = {}
		this.nextOptionsPage = 1
		this.nextSelectionPage = 1
		this.selectionDisabled = this.getAttribute('selection-disabled') !== null
		this.optionsDisabled = this.getAttribute('options-disabled') !== null
		this.dataType = this.getAttribute('endpoint')

		// Search related settings
		this.optionsSearchQuery = ''
		this.selectionSearchQuery = ''
		this.optionsSearchDisabled =
			this.getAttribute('options-search-disabled') !== null
		this.selectionSearchDisabled =
			this.getAttribute('selection-search-disabled') !== null

		// Ordering related settings
		this.availableOrderings = this.getAttribute('sortable-fields')
		if (this.availableOrderings) {
			this.availableOrderings = JSON.parse(this.availableOrderings)
		}
		// Defaults to sorting by id ascending
		this.currentOrders = { options: 'id', selection: 'id' }

		this.enableFetchingData = this.getAttribute('use-local-data') === null
		this.fetchAbortControllers = {}

		this.loadMoreText = gettext('Mehr laden')

		// Creating the DOM
		this.wrapper = this.appendChild(document.createElement('div'))
		this.wrapper.classList.add('list-builder', 'rounded')

		this.excludeId = this.getAttribute('exclude-id')

		this.optionsElements = this._buildListElement('options', {
			title: this.getAttribute('options-title'),
			searchHint: this.getAttribute('options-search-help'),
		})

		this.selectionElements = this._buildListElement('selection', {
			title: this.getAttribute('selection-title'),
			searchHint: this.getAttribute('selection-search-help'),
		})

		this.actions = this._buildActions()

		this.wrapper.appendChild(this.optionsElements.list)
		this.wrapper.appendChild(this.actions)
		this.wrapper.appendChild(this.selectionElements.list)

		if (this.enableFetchingData) {
			// We only need to show lists, if we don't fill in local data
			// local data always calls this itself in the `setData` method (which can only be executed _after_ the constructor)
			this.showLists()
		}
	}

	/**
	 * Updates member variables on attribute change of the list builder tag.
	 *
	 * {@link ListBuilder#obvservedAttributes}
	 *
	 * @param {string} name - attribute name
	 * @param {string} oldValue - current value of the attribute
	 * @param {string} newValue - new value of the attribute
	 */
	attributeChangedCallback(name, oldValue, newValue) {
		// oldValue is not used but required
		switch (name) {
			case 'selection-disabled':
				this.selectionDisabled = newValue === 'true'
				break
			case 'options-disabled':
				this.optionsDisabled = newValue === 'true'
				break
			default:
				break
		}
	}

	/**
	 * This setter disabled the fetching data from the API and instead uses given data.
	 *
	 * Make sure that you use the correct format, such that your data can be rendered correctly, as no checking is done here.
	 *
	 * @param {string} dataType - defines how to render the entries. Equivalent to what normaly would be the endpoint
	 * @param {object} data - map of ids to objects of instances
	 * @param {Array<number>} options - list of the ids of all selectable options. Ids need to be in `data`
	 * @param {Array<number>} selection - list of the ids of all selected entries that are available for selection. Ids need to be in `data`
	 */
	setData(dataType, data, options, selection) {
		this.enableFetchingData = false
		this.dataType = dataType
		this.data = data
		this.options = options
		this.selection = selection
		this.nextOptionsPage = null
		this.nextSelectionPage = null
		this.optionsSearchQuery = ''
		this.selectionSearchQuery = ''
		this._disableLoadMore()
		this.showLists()
	}

	// -------------------------------------------------------------------------
	// Getters for results
	// -------------------------------------------------------------------------
	/**
	 * Get newly selected and deselected items.
	 *
	 * @returns {object} object where they keys are object IDs and values `true` if selected or `false` if deselected
	 */
	getSelectionChanges() {
		return this.selected
	}

	/**
	 * Get the changes in selection, seperated by added/removed.
	 *
	 * @returns {object} Object with two lists of ids: added and removed.
	 */
	getChangedSelection() {
		const added = []
		const removed = []
		for (const idn in this.selected) {
			if (Object.hasOwnProperty.call(this.selected, idn)) {
				const element = this.selected[idn]
				if (element) {
					added.append(idn)
				} else {
					removed.append(idn)
				}
			}
		}
		return { added, removed }
	}

	/**
	 * Get the newly selected items.
	 *
	 * @returns  {Array<number|string>} List of ids of newly selected objects
	 */
	getNewSelected() {
		return this.getChangedSelection().added
	}

	/**
	 *   Get the newly deselected items.
	 *
	 * @returns  {Array<number|string>} List of ids of newly deselected objects
	 */
	getRemoved() {
		return this.getChangedSelection().removed
	}

	/**
	 * Retrieves data and renders it. Calling this should not be neccessary.
	 *
	 * @param {string} type - "options" or "selection" or "undefined"
	 */
	showLists(type = undefined) {
		if (this.enableFetchingData) {
			if (type !== undefined) {
				if (type === 'options') {
					this._fetchData('options', this.nextOptionsPage).then((response) =>
						this._updateData('options', response)
					)
				} else if (type === 'selection') {
					this._fetchData('selection', this.nextSelectionPage).then(
						(response) => this._updateData('selection', response)
					)
				}
			} else {
				this._fetchData('options', this.nextOptionsPage).then((response) =>
					this._updateData('options', response)
				)
				this._fetchData('selection', this.nextSelectionPage).then((response) =>
					this._updateData('selection', response)
				)
			}
		} else {
			this._orderLocalData('options')
			this._orderLocalData('selection')
			this._renderLists()
		}
	}

	// =========================================================================
	// Private functions
	// =========================================================================

	// -------------------------------------------------------------------------
	// Rendering
	// -------------------------------------------------------------------------

	/**
	 * Create a selection box with header, element list, load more button and optionally search and sorting selection
	 *
	 * @private
	 * @param {string} type - "selection" or "options"
	 * @param {object} data - object with `title` and `searchHint`. Comes from the attributes
	 * @returns {HTMLDivElement} - DOM div element
	 */
	_buildListElement(type, data) {
		const returnObj = {}
		const list = document.createElement('div')
		list.classList.add(`list-builder-${type}`, 'rounded')

		returnObj.list = list

		const header = list.appendChild(document.createElement('div'))
		header.classList.add('list-builder__header', 'rounded-top')
		header.innerText = data.title
		returnObj.header = header

		if (this.availableOrderings) {
			const sort = list.appendChild(document.createElement('select'))
			sort.classList.add('list-builder__sort', 'form-control')

			this.availableOrderings.forEach((order) => {
				const option = sort.appendChild(document.createElement('option'))
				option.value = order.key
				option.innerHTML = order.label
				option.dataset.type = type

				if (this.currentOrders[type] === order.key) {
					option.selected = true
				}
			})

			sort.addEventListener('change', (e) => {
				this.currentOrders[type] = e.target.value
				if (this.enableFetchingData) {
					if (type === 'options') {
						this.nextOptionsPage = 1
						this.options = []
					} else {
						this.nextSelectionPage = 1
						this.selection = []
					}
					this._fetchData(type, 1).then((response) =>
						this._updateData(type, response)
					)
				} else {
					this._orderLocalData(type)
					this._renderList(type)
				}
			})
			returnObj.sort = sort
		}

		if (
			(type === 'options' && !this.optionsSearchDisabled) ||
			(type === 'selection' && !this.selectionSearchDisabled)
		) {
			const search = list.appendChild(document.createElement('input'))
			search.classList.add('list-builder__search', 'form-control')
			search.setAttribute('type', 'text')
			search.setAttribute('autocomplete', 'off')
			search.setAttribute('placeholder', data.searchHint)
			search.dataset.type = type
			search.addEventListener('keyup', (e) => this._search(this, e))
			returnObj.search = search
		}

		const entries = list.appendChild(document.createElement('div'))
		entries.classList.add('list-builder__list')
		returnObj.entries = entries

		const loadMore = list.appendChild(document.createElement('button'))
		loadMore.classList.add(
			'btn',
			'btn-outline-secondary',
			'rounded-0',
			'rounded-bottom',
			'w-100'
		)
		loadMore.innerText = this.loadMoreText
		loadMore.addEventListener('click', () =>
			this._fetchData(type).then((response) => this._updateData(type, response))
		)
		returnObj.loadMore = loadMore

		return returnObj
	}

	/**
	 * Build the DOM element that contains the action buttons
	 *
	 * @private
	 * @returns {HTMLDivElement} div element
	 */
	_buildActions() {
		const actions = document.createElement('div')
		actions.classList.add('list-builder-actions')

		const actionsList = [
			{ name: 'select_all', callback: () => this._selectAll() },
			{ name: 'select', callback: () => this._select() },
			{ name: 'deselect', callback: () => this._deselect() },
			{ name: 'deselect_all', callback: () => this._deselectAll() },
		]
		actionsList.forEach((action) =>
			this._appendActionButton(actions, action.name, action.callback)
		)
		return actions
	}

	/**
	 * Helper method that creates the actual action buttons
	 *
	 * @private
	 * @param {HTMLDivElement} actions - DOM div to append actions to
	 * @param {string} actionName - "select_all", "select", "deselect_all" or "deselect"
	 * @param {Function} actionCallback - onclick callback function for the action button
	 * @returns {HTMLButtonElement} - button element
	 */
	_appendActionButton(actions, actionName, actionCallback) {
		const action = actions.appendChild(document.createElement('button'))
		action.classList.add(
			'list-builder-action-button',
			`list-builder-action-button--${actionName}`
		)
		let title = gettext('-Unbenannte Aktion-')
		const icons = { inline: '', block: '' }
		switch (actionName) {
			case 'select_all':
				title = gettext('Alle übernehmen')
				icons.inline = 'fa-chevron-double-right'
				icons.block = 'fa-chevron-double-down'
				break
			case 'select':
				title = gettext('Auswahl übernehmen')
				icons.inline = 'fa-chevron-right'
				icons.block = 'fa-chevron-down'
				break
			case 'deselect_all':
				title = gettext('Alle entfernen')
				icons.inline = 'fa-chevron-double-left'
				icons.block = 'fa-chevron-up'
				break
			case 'deselect':
				title = gettext('Auswahl entfernen')
				icons.inline = 'fa-chevron-left'
				icons.block = 'fa-chevron-double-up'
				break
			default:
				break
		}
		action.setAttribute('title', title)
		action.addEventListener('click', actionCallback)
		for (const iconType in icons) {
			if (Object.hasOwnProperty.call(icons, iconType)) {
				const icon = icons[iconType]
				const iconElement = action.appendChild(document.createElement('span'))
				iconElement.classList.add(iconType.toString())
				iconElement.innerHTML = `<i class="far ${icon}"></i>`
			}
		}
		return action
	}

	/**
	 * Convinience wrapper to render call `_renderList` for both "options" and selection"
	 *
	 * @private
	 */
	_renderLists() {
		this._renderList('options')
		this._renderList('selection')
	}

	/**
	 * Render entries into the selection box entries.
	 *
	 * @private
	 * @param {string} type - "options" or "selection"
	 */
	_renderList(type) {
		const isSelection = type === 'selection'
		const entries = isSelection
			? this.selectionElements.entries
			: this.optionsElements.entries
		entries.innerHTML = ''
		entries.insertAdjacentHTML('beforeend', this._renderEntries(isSelection))
		entries.querySelectorAll('.selectForDoubleClick').forEach((element) => {
			element.addEventListener('dblclick', () => {
				if (
					this.optionsElements.entries.innerHTML.includes(element.innerHTML)
				) {
					this._switchEntriesOnDoubleClick(element, true)
					this._renderLists()
				} else if (
					this.selectionElements.entries.innerHTML.includes(element.innerHTML)
				) {
					this._switchEntriesOnDoubleClick(element, false)
					this._renderLists()
				}
			})
		})
	}

	/**
	 * Render the list of entries into the correct box
	 *
	 * @private
	 * @param {boolean} isSelection - set to true if rendering `selection`, set false if rendering `options`
	 * @returns {string} HTML template string containing all entries
	 */
	_renderEntries(isSelection) {
		const list = isSelection ? this.selection : this.options
		let html = ''
		const searchQuery = isSelection
			? this.selectionSearchQuery
			: this.optionsSearchQuery
		const filteredList = this._filterMovedEntries(list, isSelection)
		let userId = -1
		if (this.dataType === 'member') {
			userId = getContextData('userId') || userId
		}

		filteredList.forEach((entryId) => {
			const entry = this.data[entryId]
			const id = `toggle-${isSelection ? 's' : 'o'}-${entryId}`
			const selectionDisabled =
				isSelection &&
				this.selectionDisabled &&
				entry.initialState === 'selection'
			const optionDisabled =
				!isSelection && this.optionsDisabled && entry.initialState === 'options'
			const disabled = selectionDisabled || optionDisabled ? 'disabled' : ''
			const isMoved = this.selected[entry.id] !== undefined
			const labelClass = `list-builder__entry ${disabled} ${
				isMoved ? 'list-builder__entry--moved' : ''
			} ${selectionDisabled || optionDisabled ? '' : 'selectForDoubleClick'}`
			// Make local data searchable by adding a `.hidden` CSS class
			let isHidden = false
			if (!this.enableFetchingData && searchQuery !== '') {
				for (const key in entry) {
					if (Object.hasOwnProperty.call(entry, key)) {
						const element = entry[key]
						const isNumberAndEqual =
							typeof element === 'number' && `${element}` === searchQuery
						const isStringAndIncludes =
							typeof element === 'string' && element.includes(searchQuery)
						if (isNumberAndEqual || isStringAndIncludes) {
							isHidden = false
							break
						} else {
							isHidden = true
						}
					}
				}
			}
			let entryHtml = ''
			entryHtml += `<div data-id="${entry.id}" ${
				isHidden ? 'class="hidden"' : ''
			}>`
			entryHtml += `<input type="checkbox" id="${id}" ${disabled}>`
			entryHtml += `<label class="${labelClass}" for="${id}">`
			// For more complex entries, please create a method (like in the 'invoice' case)
			let isMe = false
			let isResignated = false
			switch (this.dataType) {
				case 'member':
					if (userId !== -1) {
						isMe = userId === entry.id
					}
					isResignated = entry.resignationDate ?? false

					entryHtml += `${escapeHtml(entry.contactDetails.name)} ${
						isMe ? `(${gettext('Du')})` : ''
					} ${
						isResignated ? `(${gettext('ehem. Mitglied')})` : ''
					}<br>${escapeHtml(entry.contactDetails.city)}`
					break
				case 'contact-details':
					entryHtml += `${escapeHtml(entry.name)}<br>${escapeHtml(entry.city)}`
					break
				case 'inventory-object':
					entryHtml += `${escapeHtml(entry.name)}`
					break
				case 'invoice':
					entryHtml += this._renderInvoiceEntry(entry)
					break
				case 'billing-account':
					entryHtml += this._renderBillingAccountEntry(entry)
					break
				default:
					entryHtml += `${gettext('- Fehler -')}`
					break
			}
			entryHtml += '</label></div>'
			if (!this.excludeId || this.excludeId.toString() !== entryId.toString()) {
				html += entryHtml
			}
		})
		return html
	}

	/**
	 * Render an entry of type "billing-account".
	 *
	 * @private
	 * @param {object} entry - "invoice" instance object
	 * @returns {string} HTML template string containing the entry
	 */

	_renderBillingAccountEntry(entry) {
		return (
			'<div class="billingAccountEntry">' +
			`${entry.number}`.padStart(4, '0') +
			'</div><div class="billingAccountEntry">' +
			`${escapeHtml(entry.name)}` +
			'</div>'
		)
	}

	/**
	 * Render an entry of type "invoice".
	 *
	 * @private
	 * @param {object} entry - "invoice" instance object
	 * @returns {string} HTML template string containing the entry
	 */
	_renderInvoiceEntry(entry) {
		if (
			entry.kind === 'credit' ||
			entry.kind === 'expense' ||
			entry.canceledInvoiceIsExpense === false
		) {
			entry.totalPrice = Math.abs(entry.totalPrice) * -1
			entry.paymentDifference = Math.abs(entry.paymentDifference) * -1
		}
		let totalPrice =
			entry.charges.charge !== 0 || entry.charges.chargeBack !== 0
				? entry.charges.total
				: entry.totalPrice
		totalPrice = (totalPrice + '').replace('.', ',')
		let receiver = gettext('Nicht ausgewählt')
		if (entry.relatedAddress) {
			receiver = entry.relatedAddress.name
		} else if (entry.receiver) {
			receiver = entry.receiver.split('\n')[0]
		}
		let method
		switch (entry.paymentInformation) {
			case 'debit':
				method = gettext('Lastschrift')
				break
			case 'account':
				method = gettext('Überweisung')
				break
			case 'cash':
				method = gettext('Barzahlung')
				break
		}
		let html = '<div class="list-builder__entry--invoice">'
		html += `<div>${escapeHtml(entry.invNumber)}</div>`
		html += `<div>${totalPrice} ${entry.org.bookkeepingCurrency}</div>`
		html += `<div>${Intl.DateTimeFormat('de-DE').format(
			new Date(entry.date)
		)}</div>`
		html += `<div>${escapeHtml(receiver)}</div>`
		html += `<div>${method === undefined ? '' : method + ', '}${
			entry.actualCallStateName || gettext('Offen')
		}</div>`
		html += '</div>'
		return html
	}

	/**
	 * Helper to show the load more button for the side specified by `type`
	 *
	 * @private
	 * @param {string} type - "options" or "selection"
	 */
	_enableLoadMore(type) {
		this._setLoadMore(type, false)
	}

	/**
	 * Helper to hide the load more button for the side specified by `type`
	 *
	 * @private
	 * @param {string} type - "options" or "selection"
	 */
	_disableLoadMore(type) {
		this._setLoadMore(type, true)
	}

	/**
	 * Actualy shows or hides the given load more button by toggling a CSS class.
	 *
	 * @private
	 * @param {string} type - "options" or "selection"
	 * @param {*} addClass - if true will hide the button by adding the class, otherwise removes the class
	 */
	_setLoadMore(type, addClass) {
		const buttons = []
		if (type === undefined || type === 'options') {
			buttons.push(this.optionsElements.loadMore)
		}
		if (type === undefined || type === 'selection') {
			buttons.push(this.selectionElements.loadMore)
		}
		buttons.forEach((btn) => {
			setElementVisibility(btn, !addClass)
		})
	}

	// -------------------------------------------------------------------------
	// Selecting
	// -------------------------------------------------------------------------

	/**
	 * Move all currently visible entries from `options` to `selection`.
	 *
	 * @private
	 */
	_selectAll() {
		let selectableOptions = this._filterMovedEntries(this.options, false)
		if (this.optionsDisabled) {
			selectableOptions = selectableOptions.filter(
				(entry) => this.data[entry].initialState === 'selection'
			)
		}
		selectableOptions.map((id) => this._setSelectedState(id, true))
		this._renderLists()
	}

	/**
	 * Move all currently visible entries from `selection` to `options`.
	 *
	 * @private
	 */
	_deselectAll() {
		let removableSelection = this._filterMovedEntries(this.selection, true)
		if (this.selectionDisabled) {
			removableSelection = removableSelection.filter(
				(entry) => this.data[entry].initialState === 'options'
			)
		}
		removableSelection.map((id) => this._setSelectedState(id, false))
		this._renderLists()
	}

	/**
	 * Move all currently selected entries from `options` to `selection`.
	 *
	 * @private
	 */
	_select() {
		this._switchEntries(true)
		this._renderLists()
	}

	/**
	 * Move all currently selected entries from `selection` to `options`.
	 *
	 * @private
	 */
	_deselect() {
		this._switchEntries(false)
		this._renderLists()
	}

	/**
	 * Helper function for `_select` and `_deselect` that move selected elements between the two lists.
	 *
	 * @private
	 * @param {boolean} willBeSelected - if true will update options to be selected, other way round otherwise
	 */
	_switchEntries(willBeSelected) {
		const entriesList = willBeSelected
			? this.optionsElements.entries
			: this.selectionElements.entries

		entriesList.querySelectorAll('input:checked').forEach((node) => {
			const id = parseInt(node.parentElement.dataset.id)
			this._setSelectedState(id, willBeSelected)
		})
	}

	_switchEntriesOnDoubleClick(element, willBeSelected) {
		const id = parseInt(element.parentElement.dataset.id)
		this._setSelectedState(id, willBeSelected)
	}

	/**
	 * Sets `this.selected[id]` to `value`, if it is unset, else it removes the entry from the object.
	 *
	 * @param {number|string} id - entry ID
	 * @param {boolean} value - true means entry has been selected, false means entry has been deselected
	 */
	_setSelectedState(id, value) {
		id = parseInt(id)
		this.selected[id] = value
	}

	// -------------------------------------------------------------------------
	// Data related
	// -------------------------------------------------------------------------
	/**
	 * Sets search term. Callback function for the searchbars.
	 *
	 * @private
	 * @param {ListBuilder} listBuilder - class instance
	 * @param {Event} event - event instance
	 */
	_search(listBuilder, event) {
		const term = event.target.value
		const type = event.target.dataset.type
		if (type === 'options') {
			listBuilder.optionsSearchQuery = term
			if (listBuilder.enableFetchingData) {
				listBuilder.nextOptionsPage = 1
				listBuilder.options = []
			}
		} else {
			listBuilder.selectionSearchQuery = term
			if (listBuilder.enableFetchingData) {
				listBuilder.nextSelectionPage = 1
				listBuilder.selection = []
			}
		}
		debounce(() => {
			listBuilder.showLists(type)
		}, delay.default)()
	}

	/**
	 * Sorts local data based on `currentOrders`.
	 *
	 * @private
	 * @param {string} type - 'selection' or 'options'
	 */
	_orderLocalData(type) {
		const list = type === 'selection' ? this.selection : this.options
		const order = this.currentOrders[type]
		const desc = order.startsWith('-')
		const orderAttribute = desc ? order.replace('-', '') : order
		list.sort((a, b) => {
			const aValue = this.data[a][orderAttribute]
			const bValue = this.data[b][orderAttribute]

			if (aValue === undefined || bValue === undefined) {
				console.warn(
					`At least one of the objects does not have the field "${orderAttribute}". For the sake of sorting they are considered equal. Please make sure the orderable fields are available! Object A: ${aValue}; Object B: ${bValue}`
				)
				return 0
			}

			if (desc) {
				if (aValue > bValue) {
					return -1
				}
				if (aValue < bValue) {
					return 1
				}
				return 0
			} else {
				if (aValue < bValue) {
					return -1
				}
				if (aValue > bValue) {
					return 1
				}
				return 0
			}
		})
	}

	/**
	 * Callback to handle the `_fetchData` result. Load data into `data`, set next page number and manage load more buttons visibility.
	 *
	 * Re-renders the lists
	 *
	 * @private
	 * @param {string} type - 'selection' or 'options'
	 * @param {object} response - parsed API response
	 */
	_updateData(type, response) {
		const isSelection = type === 'selection'
		const list = isSelection ? this.selection : this.options
		// Add data to the list
		if (Object.hasOwnProperty.call(response, 'results')) {
			response.results.forEach((entry) => {
				entry.initialState = type
				this.data[entry.id] = entry
				if (!list.includes(entry.id)) {
					list.push(entry.id)
				}
			})
		}
		const hasNextUrl =
			Object.hasOwnProperty.call(response, 'next') && response.next !== null
		if (hasNextUrl) {
			// If we get a "next page" link, we will enable the load more button
			if (isSelection) {
				this.nextSelectionPage += 1
			} else {
				this.nextOptionsPage += 1
			}
			this._enableLoadMore(type)
		} else {
			// If we don't get a "next page" link, we will disable the load more button
			if (isSelection) {
				this.nextSelectionPage = null
			} else {
				this.nextOptionsPage = null
			}
			this._disableLoadMore(type)
		}

		// Filter out all "selections" from "options"
		this.options = this.options.filter((id) => !this.selection.includes(id))
		this._renderList(type)
	}

	/**
	 * Fetch data from the API.
	 *
	 * @private
	 * @param {string} type - 'selection' or 'options'
	 * @param {number} page - number of the next page to load.
	 * @returns {object} - empty if page is undefined or the request failed, else the parsed response object
	 */
	async _fetchData(type, page) {
		const isSelection = type === 'selection'
		if (!page) {
			page = isSelection ? this.nextSelectionPage : this.nextOptionsPage
		}
		if (page) {
			const loadMoreButton = isSelection
				? this.selectionElements.loadMore
				: this.optionsElements.loadMore
			loadMoreButton.blur()
			const loadMoreButtonText = this.loadMoreText
			setLoadingCircle(loadMoreButton)
			loadMoreButton.setAttribute('disabled', '')
			const endpoint = this.getAttribute('endpoint')
			const endpointQuery = this.getAttribute('endpoint-query')

			const endpointFilter = this._getEndpointFilter(isSelection)

			const searchQuery = isSelection
				? this.selectionSearchQuery
				: this.optionsSearchQuery
			const order = this.currentOrders[type]
			const url = `/api/${API_VERSION}/${endpoint}/?page=${page}&query=${endpointQuery}&${endpointFilter}&search=${searchQuery}&order=${order}`

			this._abortFetch(isSelection)
			const controller = this._createAbortController(isSelection)
			let fetchResponse
			try {
				fetchResponse = await fetch(url, { signal: controller.signal })
			} catch (error) {
				const handledErrors = [
					20, // DOMException: The user aborted a request.
				]
				if (!handledErrors.includes(error.code)) {
					console.error(error)
				}
				return {}
			}
			const response = fetchResponse
			if (response.status === 200) {
				loadMoreButton.innerHTML = loadMoreButtonText
				loadMoreButton.removeAttribute('disabled', '')
				return response.json()
			}
		}
		return {}
	}

	/**
	 * Aborts the last request, if a controller has been set up.
	 *
	 * @param {boolean} isSelection - accessor to the `this.fetchAbortControllers` property
	 */
	_abortFetch(isSelection) {
		this.fetchAbortControllers[isSelection]?.abort()
	}

	/**
	 * Removes an old, aborted controlelr and replaces it with a new one
	 *
	 * @param {boolean} isSelection - accessor to the `this.fetchAbortControllers` property
	 * @returns {AbortController} new controller instance
	 */
	_createAbortController(isSelection) {
		const oldController = this.fetchAbortControllers[isSelection]
		if (oldController && oldController.signal.aborted) {
			delete this.fetchAbortControllers[isSelection]
		}
		this.fetchAbortControllers[isSelection] = new AbortController()
		return this.fetchAbortControllers[isSelection]
	}

	/**
	 * Retrieve endpoint filter from the attributes.
	 *
	 * @private
	 * @param {boolean} isSelection - toggle that decieds whether to load optiosn or selection filter
	 * @returns {string} filter string that is passed to the API
	 */
	_getEndpointFilter(isSelection) {
		const endpoint = this.getAttribute('endpoint')
		let endpointFilter = this.getAttribute('endpoint-filter')
		if (isSelection) {
			endpointFilter = `${endpointFilter}&${this.getAttribute(
				'endpoint-selection-filter'
			)}`
		} else {
			endpointFilter = `${endpointFilter}&${this.getAttribute(
				'endpoint-options-filter'
			)}`
		}
		if (
			endpoint === 'invoice' &&
			!isSelection &&
			this.optionsSearchQuery !== ''
		) {
			endpointFilter = `${this.getAttribute(
				'endpoint-allInvoicesFilter'
			)}&isOpenForBookingFilter=true`
		}
		return endpointFilter
	}

	/**
	 * Filter moved entries (this.selected) out of list.
	 *
	 * @param {Array<number>} list - list containing data ids
	 * @param {boolean} isSelection - true if `list` is this.selection, false if it is this.options
	 * @returns {Array<number>} list, but filtered out moved data
	 */
	_filterMovedEntries(list, isSelection) {
		const deselected = []
		const selected = []
		for (const dataId in this.selected) {
			if (Object.hasOwnProperty.call(this.selected, dataId)) {
				const isSelected = this.selected[dataId]
				const intId = parseInt(dataId)
				if (isSelected) {
					selected.push(intId)
				} else {
					deselected.push(intId)
				}
			}
		}
		let filteredList = list.filter(
			(id) => !selected.includes(id) && !deselected.includes(id)
		)
		if (isSelection) {
			filteredList = [...selected, ...filteredList]
		} else {
			filteredList = [...deselected, ...filteredList]
		}
		return filteredList
	}
}

customElements.define('list-builder', ListBuilder)
