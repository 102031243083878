import { alertClose, alertLoading } from '../globals/alerts'
import { gettext } from './translation'

/**
 * handles the file upload with a loader in a alert
 *
 * usage (with API):
 * await fileUploadHandler(async () => { await uploadFile(...) })
 *
 * @param {Function} uploadFunction - the function which is uploading the file
 *
 * @returns {any} returns the result of the upload function, can be undefined
 */
export async function fileUploadHandler(uploadFunction) {
	if (!uploadFunction) {
		console.error('uploadFunction is missing in fileUploadHandler')
		return
	}

	alertLoading(
		undefined,
		gettext('Deine Dateien werden verarbeitet, bitte hab einen Moment Geduld'),
		{
			title: `${gettext('Datei-Upload läuft')}...`,
		}
	)

	const result = await uploadFunction()

	alertClose()

	return result
}
