import { show } from '../utils/helpers/show'
import initCKEditor, { getEditorBySelector } from '../utils/ckEditor'
import { eventListenerAdd } from '../utils/helpers/eventHelpers'
import { get, getByUrl, patch, post, uploadFile } from '../api/crud'
import { unescapeHtml } from './html'
import { apiError } from '../api/alerts'
import { formatDateForAPI, formatDateFromAPI } from './helpers/dateFormatter'
import { fileUploadHandler } from './fileUpload'

/**
 * Requires:
 *     {% include 'app/partials/customFields/customFieldsSection.html' with ids='customfield' %} // (optional inside a id="customFields" div)
 *
 * Purpose:
 *     This file combined with the customFieldsSection.html and optional the customFieldsHelper.js summarize the whole custom field assignemnts.
 *     Using the API this script is capable to show all customfields and their values if needed, let the user change or create some values
 *
 * How to use:
 *     All requirements needs to be set in the page html.
 *     The initCustomFieldListener() must be triggered in the init/setup of the page JS.
 *     Make shure that you dont initialize the CKEditor for any customfield, use the `:not(.customfield)` as selector if needed.
 *     To fill in the customfield data just call the fillCustomFieldValues() with the right API endpoint for the custom-fields.
 *     To send changed or created values to the backend just call the collectCustomFields() with the right API endpoint for the custom-fields.
 *     Call the resetAllChangedCustomFields() or the clearCustomFieldsData() of the customFieldsHelper.js if needed.
 */

/**
 * Enum of the CustomField settings_types.
 */
export const CustomFieldType = Object.freeze({
	OneLineString: 't',
	MultiLineString: 'f',
	IntegerField: 'z',
	Date: 'd',
	Boolean: 'c',
	DateFromTo: 'r',
	Selectfield: 's',
	MultiSelectfield: 'a',
	File: 'b',
	Reminder: 'm',
})

/**
 * Toggles the chevron (icon on the right) alignment
 *
 * @param {Event?} event - Click event
 */
export function toggleCustomFields(event) {
	if (event) {
		event.preventDefault()
	}
	const element = event.currentTarget
	const iconSpan = element.querySelector('span')
	iconSpan.innerHTML = iconSpan.innerHTML.includes('fa-chevron-left')
		? '<i class="far fa-chevron-down"></i>'
		: '<i class="far fa-chevron-left"></i>'
}

/**
 * Init the listener for the custom fields to detect value changes
 * Init the ckeditors for the custom fields
 *
 * @param {string} ckeditorSelector - (optional) The selector of the ckeditors if not all textarea customfields should be a ckeditor
 */
export async function initCustomFieldListener(
	ckeditorSelector = 'textarea.customfield'
) {
	for await (const textarea of document.querySelectorAll(ckeditorSelector)) {
		const editor = await initCKEditor(
			`#${textarea.id}`,
			false,
			true,
			false,
			true
		)
		if (editor)
			editor.ui.focusTracker.on(
				'change:isFocused',
				(event, data, isFocused) => {
					if (!isFocused) {
						setWasChanged(textarea)
						const changeEvent = new Event('change')
						textarea.dispatchEvent(changeEvent)
					}
				}
			)
		else
			textarea.addEventListener('change', (event) =>
				setWasChanged(event.currentTarget)
			)
	}

	eventListenerAdd(
		{
			'.customfield:not(select-us):not(textarea)': (event) =>
				setWasChanged(event.currentTarget),
		},
		'change'
	)
	eventListenerAdd(
		{
			'.customfield.datemask': (event) => setWasChanged(event.currentTarget),
		},
		'changeDate'
	)
	eventListenerAdd(
		{
			'select-us.customfield': (event) => setWasChanged(event.currentTarget),
		},
		'selected'
	)
}

/**
 * Set or reset the waschanged attribute of a custom field
 *
 * @param {Element} element - The dom element to set or reset the waschanged attribute
 * @param {boolean} reset - Reset the waschanged attribute
 */
export function setWasChanged(element, reset = false) {
	element.dataset.waschanged = !reset

	// ensure waschanged is set for both inputs of dateranges
	if (element.id.includes('-to')) {
		const dateRangeFrom = document.querySelector(
			`#${element.id.replace('-to', '')}`
		)
		if (dateRangeFrom) {
			dateRangeFrom.dataset.waschanged = !reset
		}
	}
}

/**
 * Reset the waschanged and assignmentid attribute of all custom fields
 * This does not remove the value!
 *
 * @param {boolean} [resetAssignmentid=true] - Reseting the assignmen id of the fields
 */
export function resetAllChangedCustomFields(resetAssignmentid = true) {
	document
		.querySelectorAll('.customfield[data-waschanged="true"]')
		.forEach((customField) => {
			customField.dataset.waschanged = false
		})
	// the deleted assignmentid leads to an error when we want to change the data of a custom field without reloading after a previous change in appProfile
	if (resetAssignmentid) {
		document
			.querySelectorAll('.customfield:not([data-assignmentid=""])')
			.forEach((customField) => {
				customField.dataset.assignmentid = ''
			})
	}
}

/**
 * Collects the values of the custom fields
 *
 * @param {string} endpoint - The endpoint to the customfield assignments
 * @param {boolean} [resetAssignmentid=true] - Reseting the assignmen id of the fields
 * @returns {boolean} - A boolean indicating that the requests were successfull or not
 */
export async function collectCustomFields(endpoint, resetAssignmentid = true) {
	// {post: {$customFieldId: {customField: $customFieldId, value: $value}}, patch: {$assignmentId: {customField: $customFieldId, value: $value}}}
	// delete is not necessary - just clear the value to "unset" the field
	const data = { post: {}, patch: {} }
	// file custom fields are a special case - they first must get posted and than patched if they dont exist
	const fileData = { post: {}, patch: {} }

	document
		.querySelectorAll('.customfield[data-waschanged="true"]')
		.forEach((customField) => {
			collectData(customField, data, fileData)
		})
	const success = await sendData(endpoint, data)
	const fileSuccess = await sendFileData(endpoint, fileData)
	if (success && fileSuccess) {
		resetAllChangedCustomFields(resetAssignmentid)
	}
	return success && fileSuccess
}

/**
 * Send the data to the backend using the API
 *
 * @param {string} endpoint - The endpoint to the customfield assignments
 * @param {object} data - The data object to send to the backend
 * @returns {boolean} - A boolean indicating that the requests were successfull or not
 */
async function sendData(endpoint, data) {
	const bulkPost = []
	const bulkPatch = []
	parseDataToBulkableEntries(data, bulkPost, bulkPatch)

	let response
	if (bulkPost.length) {
		response = await post(
			`${endpoint}/bulk-create`,
			{ entries: bulkPost },
			false
		)
	}
	if (bulkPatch.length) {
		response = await patch(
			`${endpoint}/bulk-update`,
			'',
			{ entries: bulkPatch },
			false
		)
	}
	if (response && response.data) {
		return checkBulkResponse(response)
	} else if (!bulkPatch.length && !bulkPost.length) {
		// Returning true even if no field was changed because the requests technically were not unsuccessful
		return true
	}
	return false
}

/**
 * Checks the response of the API bulk endpoints and informs the user if some error occured
 *
 * @param {object} response - The response of the API bulk endpoint
 * @returns {boolean} - A boolean indicating that the requests were successfull or not
 */
async function checkBulkResponse(response) {
	if (![200, 201].includes(response.status)) {
		const bulkedResponses = response.data
		const failedResponses = bulkedResponses.filter(
			(resp) => ![200, 201].includes(resp.status) && resp.data
		)
		const errors = failedResponses.map((resp) => resp.data)
		await apiError(response.status, errors)
		return false
	}
	return true
}

/**
 * Checks the response of the API endpoint and informs the user if some error occured
 *
 * @param {object} response - The response of the API endpoint
 * @returns {boolean} - A boolean indicating that the request were successfull or not
 */
async function checkResponse(response) {
	if (![200, 201].includes(response.status)) {
		const data = response.data ?? (await response.json())
		await apiError(response.status, data)
		return false
	}
	return true
}

/**
 * Convertes the data object into two arrays of entries to bulk post or patch this entries
 *
 * @param {object} data - The data object to send to the backend
 * @param {Array} bulkPost - The bulk postable entries
 * @param {Array} bulkPatch - The bulk patchable entries
 * @returns {Array} - An array containing the two entries for bulk post or patch
 */
function parseDataToBulkableEntries(data, bulkPost, bulkPatch) {
	for (const methodName of Object.keys(data)) {
		const method = data[methodName]
		for (const entryId of Object.keys(method)) {
			const customFieldData = method[entryId]
			if (methodName === 'post') bulkPost.push(customFieldData)
			else {
				customFieldData.id = entryId
				bulkPatch.push(customFieldData)
			}
		}
	}
	return [bulkPost, bulkPatch]
}

/**
 * Handle the file upload process - creates a new empty entry if necessary and uploads the file or resets the file custom field
 *
 * @param {string} endpoint - The endpoint to the customfield assignments
 * @param {object} fileData - The fileData object containing the files to upload
 * @returns {boolean} - A boolean indicating that the request were successfull or not
 */
async function sendFileData(endpoint, fileData) {
	const responses = []
	// It is necessary to first post all file fields if they dont exist and than patch the real file
	for await (const methodName of Object.keys(fileData)) {
		// { post: {1}, patch: {2} } => {1: {1}}
		const method = fileData[methodName]
		for await (const entryId of Object.keys(method)) {
			// {1: {1}} => {customField: $id, value: $FormDataContainingFile}
			const customFieldData = method[entryId]
			let fileCustomFieldId = entryId
			if (methodName === 'post') {
				const initialData = {
					customField: customFieldData.customField,
					value: '',
				}
				const createFieldResponse = await post(endpoint, initialData)
				fileCustomFieldId = createFieldResponse.data.id
			}
			let response
			if (customFieldData.value === '') {
				// reset the file field
				response = await patch(endpoint, fileCustomFieldId, { value: '' })
			} else {
				// set the file field
				await fileUploadHandler(async () => {
					response = await uploadFile(
						endpoint,
						fileCustomFieldId,
						customFieldData.value,
						'value'
					)
				})
			}
			responses.push(await checkResponse(response))
		}
	}
	if (responses.includes(false)) {
		return false
	}
	// Returning true even if no field was changed because the requests technically were not unsuccessful
	return true
}

/**
 * Collect the value of the customfield element in the data object
 *
 * @param {object} customField - The customfield input element containing the value to save
 * @param {object} data - The data object to fill in the values to transfer them to the server
 * @param {object} fileData - The fileData object to fill in the file input field files to transfer them to the server
 * @returns {object} - The data object
 */
function collectData(customField, data, fileData) {
	const assignmentId = customField.dataset.assignmentid
	const customFieldId = getCustomFieldId(customField)
	let value = customField.value || ''
	// If the custom field is a file field and it is not checked it should not be deleted
	if (customField.id.includes('delete-customfield')) {
		if (!customField.checked) {
			return ''
		}
		customField.checked = false
	}
	if (customField.id.endsWith('-to')) {
		// Its the first input of a date range - ignore it
		return data
	}
	const customFieldID = customField.dataset.id

	if (customField.classList.contains('datemask')) {
		const [firstDateInput, secondDateInput] =
			getFirstAndSecondDateInputs(customFieldID)
		if (firstDateInput && secondDateInput) {
			// Date from X to Y
			value = `${
				firstDateInput.value ? formatDateForAPI(firstDateInput.value) : ''
			},${secondDateInput.value ? formatDateForAPI(secondDateInput.value) : ''}`
			value = value === ',' ? '' : value
		} else {
			value = formatDateForAPI(customField.value)
		}
	} else if (customField.type === 'textarea') {
		// Textarea or ckeditor
		value = getEditorBySelector(`#${customField.id}`)
			? getEditorBySelector(`#${customField.id}`)?.getData()
			: customField.value
	} else if (customField.type === 'file') {
		// File
		// Files are a special case - they get their own data object to get filled in
		value = customField.files.length ? customField.files[0] : undefined
		fillValueToData(fileData, value, assignmentId, customFieldId)
		return [data, fileData]
	} else {
		// All other kinds of custom fields including checkboxes and multi selection
		if (customField.type === 'checkbox') {
			if (!customField.id.includes('delete-')) {
				// regular check custom field
				value = customField.checked ? 'True' : 'False'
			} else {
				// Its not a custom field - its the deletion input checkbox for a file custom field
				// value = '' to reset the file field
				fillValueToData(fileData, '', assignmentId, customFieldId)
				return [data, fileData]
			}
		} else if (customField.nodeName === 'SELECT-US') {
			value = JSON.stringify(customField.selectedValues)
		}
	}

	fillValueToData(data, value, assignmentId, customFieldId)

	return [data, fileData]
}

/**
 * Get the first and second date inputs of the customfield if they does exist
 *
 * @param {string|number} customFieldID - The id of the customfield
 * @returns {Array} - An array containing the first and second date input fields if they exist
 */
function getFirstAndSecondDateInputs(customFieldID) {
	const container = document.querySelector('.modal.show') || document
	// cCustomfield is just for appAddressbook because it is stupidly made
	const firstDateInput =
		container.querySelector(`#customfield-${customFieldID}`) ||
		container.querySelector(`#cCustomfield-${customFieldID}`)
	const secondDateInput =
		container.querySelector(`#customfield-${customFieldID}-to`) ||
		container.querySelector(`#cCustomfield-${customFieldID}-to`)

	return [firstDateInput, secondDateInput]
}

/**
 * Get the custom field id
 *
 * @param {Element} customField - The customField DOM element to get the id from
 * @returns {string} The id of the customfield (not the assignment!)
 */
function getCustomFieldId(customField) {
	const splitedId = customField.id.split('-')
	return splitedId[splitedId.length - 1]
}

/**
 * Fill in the given value into the given data object depending on the value and assignmentId
 *
 * @param {object} data - The data object to fill in the values to transfer them to the server (either data or fileData)
 * @param {string|File} value - The content of the custom field, either as string or file if it is a file custom field
 * @param {string} assignmentId - The id of the custom field assignment if it already exist
 * @param {string} customFieldId - The id of the custom field
 * @returns {object} - The filled data object
 */
function fillValueToData(data, value, assignmentId, customFieldId) {
	if (value !== undefined) {
		if (assignmentId)
			data.patch[assignmentId] = { customField: customFieldId, value: value }
		else data.post[customFieldId] = { customField: customFieldId, value: value }
	}

	return data
}

/**
 * Get the fields values from the API and fill this data into the html elements
 *
 * @param {string} endpoint - The endpoint to the customfield assignments
 * @param {string} ids - (optional) The first part of the element id, should be allways 'customfield-' (default), only for addressbook 'cCustomfield-' as well
 */
export async function fillCustomFieldValues(endpoint, ids = 'customfield-') {
	const limit = 100 // every page has 100 entries
	const customFieldAssignments = await get(
		endpoint,
		'{id,value,customField{id,settings_type,name}}',
		{ deleted: false },
		limit
	)
	const maxPages = customFieldAssignments.data.count / limit
	if (customFieldAssignments.data.next) {
		let nextPage = customFieldAssignments.data.next
		for (let i = 1; i < maxPages; i++) {
			const nextPageAssignments = await getByUrl(nextPage)
			nextPage = nextPageAssignments.data.next
			customFieldAssignments.data.results =
				customFieldAssignments.data.results.concat(
					nextPageAssignments.data.results
				)
		}
	}

	for (const fieldAssignment of customFieldAssignments.data.results) {
		const value = fieldAssignment.value // no escapeHTML needed cause html is not rendered in input fields
		const customField = fieldAssignment.customField
		const customFieldID = customField.id
		const customFieldInput = document.querySelector(`#${ids}${customFieldID}`)

		// value can be '' (this is legitim)
		if (value !== undefined && customFieldInput) {
			customFieldInput.dataset.assignmentid = fieldAssignment.id
			if (customField.settings_type === CustomFieldType.Boolean) {
				if (value === 'True') {
					customFieldInput.checked = true
				}
			} else if (
				customField.settings_type === CustomFieldType.MultiLineString
			) {
				getEditorBySelector(`#${ids}${customFieldID}`).setData(
					unescapeHtml(value)
				)
			} else if (customField.settings_type === CustomFieldType.File) {
				if (value !== '') {
					document.querySelector(`#${ids}path-${customFieldID}`).href =
						fieldAssignment.value
					// Some keys are unknown
					show(
						`.costumFieldDataLink[id*="${customFieldID}"], #deletecheck-${ids}${customFieldID}, #${ids}path-${customFieldID}`
					)
					// The download link - value contains the whole link, so it must be splited to only get the name
					document.querySelector(`#download-${ids}${customFieldID}`).innerHTML =
						value.split('path=')[1]
					document.querySelector(
						`#delete-${ids}${customFieldID}`
					).dataset.assignmentid = fieldAssignment.id
				}
			} else if (
				customField.settings_type === CustomFieldType.MultiSelectfield &&
				fieldAssignment.value
			) {
				customFieldInput.setSelection(JSON.parse(fieldAssignment.value))
			} else if (customField.settings_type === CustomFieldType.DateFromTo) {
				if (value !== '') {
					const dates = value.split(',')
					customFieldInput.value = formatDateFromAPI(dates[0])
					document.querySelector(`#${ids}${customFieldID}-to`).value =
						formatDateFromAPI(dates[1])
				}
			} else if (
				[CustomFieldType.Date, CustomFieldType.Reminder].includes(
					customField.settings_type
				)
			) {
				customFieldInput.value = formatDateFromAPI(value)
			} else {
				customFieldInput.value = value || ''
			}
		}
	}
}

/**
 * Clears the value of all custom fields.
 *
 * @deprecated see the top of `utils/customFields.js` for a guide on how to replace it.
 */
export function clearCustomFieldsData() {
	console.error(
		'"clearCustomFieldsData" is deprecated. Check the top of utils/customFields.js for a guide how to replace it with the corret new way.'
	)
}

/**
 * Collects the values of the custom fields
 *
 * @deprecated see the top of `utils/customFields.js` for a guide on how to replace it.
 */
export function collectCustomFieldsData() {
	console.error(
		'"collectCustomFieldsData" is deprecated. Check the top of utils/customFields.js for a guide how to replace it with the corret new way.'
	)
}

/**
 * Renders the selected values of a custom multi select field down to a simple string.
 *
 * @param {string} jsonStr - JSON of an array
 * @returns {string} string of selected values
 */
export function convertCustomFieldMutliSelect(jsonStr) {
	try {
		const data = JSON.parse(jsonStr)
		const array = Array.isArray(data) ? data : Object.values(data)
		return Object.values(array).join(', ')
	} catch {
		return ''
	}
}
