import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import {
	Bold,
	Italic,
	Underline,
	Subscript,
	Superscript,
	Strikethrough,
} from '@ckeditor/ckeditor5-basic-styles/'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import { FontSize, FontColor, FontFamily } from '@ckeditor/ckeditor5-font'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import { Link, LinkImage } from '@ckeditor/ckeditor5-link'
import { List } from '@ckeditor/ckeditor5-list'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import {
	Image,
	ImageInsert,
	ImageToolbar,
	ImageStyle,
	ImageCaption,
	ImageResize,
	ImageEditing,
	ImageTextAlternative,
} from '@ckeditor/ckeditor5-image'
import { Table } from '@ckeditor/ckeditor5-table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { PageBreak } from '@ckeditor/ckeditor5-page-break'
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line'
import {
	SpecialCharacters,
	SpecialCharactersEssentials,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
} from '@ckeditor/ckeditor5-special-characters'
import { EasyUploadAdapterPlugin } from './ckEditorImageUploadAdapter'

/**
 * Utility class that manages the allowed settings for a ckeditor
 */
export class AllowedSettings {
	constructor({
		allowMedia = false,
		allowPageBreak = false,
		allowTables = false,
		allowAdvancedFontOptions = false,
		allowSpecialChars = false,
	} = {}) {
		this.allowMedia = allowMedia
		this.allowPageBreak = allowPageBreak
		this.allowTables = allowTables
		this.allowAdvancedFontOptions = allowAdvancedFontOptions
		this.allowSpecialChars = allowSpecialChars
	}
}

/**
 * Utility class that helps building the ckeditors's plugins and toolbar
 */
export class Configuration {
	constructor() {
		this.advancedFontOptions = {
			toolbar: ['heading', 'fontColor', 'fontSize', 'fontFamily', 'highlight'],
			plugins: [Heading, FontColor, FontSize, FontFamily, Highlight],
		}

		this.mediaOptions = {
			toolbar: ['imageInsert'],
			plugins: [
				Image,
				ImageInsert,
				ImageToolbar,
				ImageStyle,
				ImageCaption,
				ImageResize,
				ImageEditing,
				ImageTextAlternative,
				LinkImage,
				EasyUploadAdapterPlugin,
			],
		}

		this.pageBreakOptions = {
			toolbar: ['pageBreak'],
			plugins: [PageBreak],
		}

		this.tableOptions = {
			toolbar: ['insertTable'],
			plugins: [Table, TableToolbar, TableProperties, TableCellProperties],
		}

		this.specialCharactersOptions = {
			toolbar: ['specialCharacters'],
			plugins: [
				SpecialCharacters,
				SpecialCharactersEssentials,
				SpecialCharactersArrows,
				SpecialCharactersCurrency,
				SpecialCharactersLatin,
				SpecialCharactersMathematical,
				SpecialCharactersText,
			],
		}
	}

	/**
	 * Remove different toolbar options depending on the settings
	 * Note: Dividers and horizontal lines don't need to be removed
	 *
	 * @param {Array<string> | Array<any>} list - list of plugins or toolbar configuration
	 * @param {boolean} pluginsInList - if true `list` contains plugins (classes), else toolbar strings
	 * @param {AllowedSettings} settings - allowed plugins settings, filters all that are not allowed.
	 * @param {Array<string>} disallowedTools - List of tools to hide from the toolbar.
	 * @returns {Array<string> | Array<any>} filtered `list`
	 */
	filter(list, pluginsInList, settings, disallowedTools = []) {
		const filters = [
			{
				condition: !settings.allowAdvancedFontOptions,
				options: this.advancedFontOptions,
			},
			{ condition: !settings.allowMedia, options: this.mediaOptions },
			{ condition: !settings.allowPageBreak, options: this.pageBreakOptions },
			{ condition: !settings.allowTables, options: this.tableOptions },
			{
				condition: !settings.allowSpecialChars,
				options: this.specialCharactersOptions,
			},
		]
		const accessor = pluginsInList ? 'plugins' : 'toolbar'
		filters.forEach((filter) => {
			if (filter.condition) {
				list = list.filter((item) => !filter.options[accessor].includes(item))
			}
		})
		if (disallowedTools.length > 0) {
			disallowedTools.forEach((disallowedTool) => {
				list = list.filter((item) => item !== disallowedTool)
			})
		}
		return list
	}
}

/**
 * Default plugin `configuration` instance
 */
export const DefaultPluginConfiguration = new Configuration()

/**
 * Defines the enabled plugins
 *
 * @param {Configuration} configuration - static configuration
 * @param {AllowedSettings} allowed - which plugins are allowed
 * @returns {Array<any>} list of enabled plugins
 */
export function configurePlugins(configuration, allowed) {
	const plugins = [
		Essentials,
		...configuration.advancedFontOptions.plugins,
		Bold,
		Italic,
		Underline,
		Strikethrough,
		Subscript,
		Superscript,
		Link,
		List,
		Indent,
		Alignment,
		...configuration.mediaOptions.plugins,
		...configuration.tableOptions.plugins,
		BlockQuote,
		...configuration.pageBreakOptions.plugins,
		HorizontalLine,
		...configuration.specialCharactersOptions.plugins,
	]
	return configuration.filter(plugins, true, allowed)
}

/**
 * Defines the enabled toolbar elements
 *
 * @param {Configuration} configuration - static configuration
 * @param {AllowedSettings} allowed - which toolbar elements are to be shown
 * @param {Array<string>} disallowedTools - List of tools to hide from the toolbar
 * @returns {Array<string>} list of toolbar elements to show
 */
export function configureToolbar(configuration, allowed, disallowedTools) {
	const toolbar = [
		'undo',
		'redo',
		'|',
		...configuration.advancedFontOptions.toolbar,
		'|',
		'bold',
		'italic',
		'underline',
		'strikethrough',
		'subscript',
		'superscript',
		'link',
		'|',
		'bulletedList',
		'numberedList',
		'|',
		'outdent',
		'indent',
		'alignment',
		'|',
		...configuration.mediaOptions.toolbar,
		...configuration.tableOptions.toolbar,
		'blockQuote',
		...configuration.pageBreakOptions.toolbar,
		'horizontalLine',
		...configuration.specialCharactersOptions.toolbar,
	]
	return configuration.filter(toolbar, false, allowed, disallowedTools)
}
