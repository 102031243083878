import $ from 'jquery'
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'
import Colors from '../design/colors'
import { confirmTaskStarted } from './asyncTasksUtilities'
import { getMessageText, successMessage } from '../globals/messages'
import {
	getRecordsDisplay,
	getRecordsTotal,
	isFilteredBySearch,
} from './datatables'
import { headers } from './helpers/apiHelpers'
import { show, hide, showElement, hideElement } from './helpers/show'
import { gettext, interpolate } from './translation'
import { getWithExpiry, setWithExpiry } from './localStorage'
import {
	alertCustom,
	alertDanger,
	alertInformation,
	alertWarning,
	alertWarningConfirmation,
} from '../globals/alerts'
import { loader } from '../utils/loader.mjs'
import { patch } from '../api/crud'

const maData = {
	action: null,
	all: false,
	collection: null,
	table: null,
	model: null,
	wastebasket: false,
}

const getTable = () => {
	return (
		document.querySelector('.tab-pane.active')?.querySelector(maTable) ||
		document.querySelector(maTable)
	)
}

let maTable = null
let maLable = null
let maState = false
const maPreActionCallbacks = {}
const maActionCallbacks = {}

/**
 * Sets up mass actions on the page
 *
 * @param {Array<string, string, ?Function, ?Function>} actions - [id, label, afterActionCallback, beforeActionCallback]. Id needs to be part of the backend, beforeActionCallback needs to return a promise! Otherwise it will just run through
 * @param {string} actions.id - first array element. Needs to be a backend id
 * @param {string} actions.label - second array element. Display name of the action
 * @param {Function?} actions.afterActionCallback - third array element. Callback function that is called _after_ the mass action has been send to the backend
 * @param {Function?} actions.beforeActionCallback - forth array element. Callback function that is called _before_ the mass action is send to the backend
 * @param {string} label - mass action label
 * @param {string} model - model to work on
 * @param {string} tableSelector - mass action table id
 * @param {Function} customOptionsCallback - callback function called after `toggleMassActionMode`
 * @param {boolean} wastebasket - wether we are inside the wastebasket
 * @param {object} bookingManager - The bookingManager needed for booking massActions when timline view is active
 */
export function initializeMassAction(
	actions,
	label,
	model,
	tableSelector,
	customOptionsCallback,
	wastebasket,
	bookingManager = null
) {
	maData.model = model
	maData.table = tableSelector
	maData.wastebasket = wastebasket

	maTable = tableSelector
	maLable = label

	initMassActionSelection()
	initModal(actions, label, customOptionsCallback, bookingManager)
}

/**
 * Changes the table id of the massaction table (in case the page has multible tabs)
 *
 * @param {string} model - model to work on
 */
export function changeModel(model) {
	maData.model = model
}

/**
 * Initializes the modalMassAction.html modal
 *
 * @param {Array} actions - possible mass actions
 * @param {string} label - mass action label
 * @param {Function} customOptionsCallback - customOptions callback function from initializeMassAction
 */
function initModal(actions, label, customOptionsCallback) {
	const maModal = document.querySelector('#maModal')
	const openFilterModalButton = document.querySelector('#maActionOpenFilter')
	if (maData.wastebasket) {
		hideElement(openFilterModalButton)
	} else {
		openFilterModalButton.addEventListener('click', () =>
			Modal.getOrCreateInstance(document.querySelector('#filter'))?.show()
		)
		showElement(openFilterModalButton)
	}
	maModal.querySelectorAll('.maActionLabel').forEach((span) => {
		span.innerText = label
	})
	let options = ''
	actions.forEach((action) => {
		const [id, label, callback, preActionCallback] = action
		options += `<option id="${id}" value="${id}">${label}</option>`
		maActionCallbacks[id] = callback
		maPreActionCallbacks[id] = preActionCallback
	})
	const maAction = document.querySelector('#maAction')
	maAction.insertAdjacentHTML('beforeend', options)
	const toggleActionMode = () => {
		if (customOptionsCallback) {
			customOptionsCallback?.call()
		}
		toggleMassActionMode()
	}
	maAction.addEventListener('change', () => {
		toggleActionMode()
	})
	document.querySelector('#maSelect').addEventListener('change', () => {
		toggleMassActionMode()
	})
	document.querySelector('#maModal').addEventListener('show.bs.modal', () => {
		toggleMassActionMode()
	})
	toggleActionMode()
}

/**
 * Initializes the selection mode for mass actions
 */
function initMassActionSelection() {
	const maSelection = document.querySelector('#maSelection')
	if (!maSelection) {
		console.error(
			'Partial "massActionSelection" is missing. Make sure to include it! '
		)
	}
	const showFiltersButton = maSelection.querySelector('#maSelectionShowFilters')
	if (maData.wastebasket) {
		showFiltersButton.addEventListener('click', () => {
			Modal.getInstance(document.querySelector('#filter')).show()
		})
		showElement(showFiltersButton)
	} else {
		hideElement(showFiltersButton)
	}
	maSelection
		.querySelector('#maSelectionSelectAllVisible')
		.addEventListener('click', selectAllVisibleData)
	maSelection
		.querySelector('#maSelectionUnselectAllVisible')
		.addEventListener('click', unselectAllVisibleData)

	maSelection
		.querySelector('#maSelectionReset')
		.addEventListener('click', resetMassAction)

	document
		.querySelector('#maSelectionExecuteAction')
		.addEventListener('click', startMassAction)
}

/**
 * Highlights an element if it is selected for a mass action
 *
 * @param {Element} element - element to highlight
 */
export function highlightMassActionData(element) {
	if (!maState || !maData.collection) {
		return
	}
	const id = element.id

	if (!id) {
		return
	}

	if (maData.collection.indexOf(id) > -1) {
		element.classList.add('bg-primary')
	}
}

/**
 * Selects all rows of the table currently visible
 */
function selectAllVisibleData() {
	document
		.querySelectorAll('tr[id]:not(.bg-primary,.bg-disabled)')
		.forEach((row) => selectMassActionData(row))
}

/**
 * Deselects all rows of the table currently visible
 */
function unselectAllVisibleData() {
	document
		.querySelectorAll('.bg-primary')
		.forEach((row) => selectMassActionData(row))
}

/**
 * (De-)selects the given element and manages the selected data array
 *
 * @param {object} element - DOM element to (de-)select
 */
async function selectMassActionData(element) {
	if (!maState) {
		return
	}
	const id = element.id

	if (!id || isNaN(id)) {
		return
	}

	if (element.dataset.unselectablefor?.includes(maData.action)) {
		const errorCode =
			element.dataset[`error_${maData.action}`] || 'TK403xEntryNotSelectable'
		alertWarning(errorCode, undefined, {
			shortDescriptionOnly: true,
		})
		return
	}
	const idx = maData.collection.indexOf(id)

	if (idx < 0) {
		maData.collection.push(id)
	} else {
		maData.collection.splice(idx, 1)
	}

	element.classList.toggle('bg-primary')
}

/**
 * Shows controls for mass action selection (all buttons etc.)
 */
function showMassActionSelection() {
	const htmlTable = getTable()
	htmlTable?.classList.add('massActionTable--selectable')
	setSelectMassActionDataListener(htmlTable)

	maState = true
	maData.collection = []

	show('#maSelection')
	if (!htmlTable.dataset.unselectedactionset) {
		const dataTable = $(`#${htmlTable.id}`)
		dataTable.on('draw.dt', () => {
			setUnselectableState()
		})
		// The on-draw should only be set once to avoid redundant calls of the same function
		htmlTable.dataset.unselectedactionset = true
	}
	setUnselectableState()
}

/**
 * Adds the unselectable class to matching rows
 */
function setUnselectableState() {
	const htmlTable = getTable()
	htmlTable?.querySelectorAll('tbody tr').forEach((row) => {
		if (row.dataset.unselectablefor?.includes(maData.action)) {
			row.classList.add('bg-disabled', 'cursor-not-allowed')
		}
	})
}

/**
 * Set the event listener for the massaction table only if its not allready set
 *
 * @param {Element} table - The table element to set the massaction for
 * @returns {undefined} Nothing
 */
function setSelectMassActionDataListener(table) {
	if (table.dataset.allreadyhaseventlistener === 'true') return
	table.addEventListener('click', (event) => {
		const tr = event.target.parentNode
		if (tr && tr.nodeName === 'TR') {
			selectMassActionData(tr)
		}
	})
	table.dataset.allreadyhaseventlistener = 'true'
}

/**
 * Cancels a mass action and removes the selection
 */
export function resetMassAction() {
	hide('#loading')
	hide('#maSelection')

	const table = getTable()
	table?.classList.remove('massActionTable--selectable')
	setSelectMassActionDataListener(table)

	maData.action = null
	maData.collection = null
	maData.table = maTable

	maState = false

	table?.querySelectorAll('tbody tr').forEach((row) => {
		row.classList.remove('bg-disabled', 'bg-primary', 'cursor-not-allowed')
	})
}

/**
 * Switches between different mass action modes
 */
async function toggleMassActionMode() {
	const recordsDisplay = getRecordsDisplay(maData.table)
	const recordsTotal = getRecordsTotal(maData.table)

	document.querySelectorAll('.maRecordsDisplay').forEach((node) => {
		node.textContent = recordsDisplay
	})
	document.querySelectorAll('.maRecordsTotal').forEach((node) => {
		node.textContent = recordsTotal
	})

	document.querySelector('#maSelect--manual').textContent = interpolate(
		gettext('%(objectName)s von Hand selektieren'),
		{ objectName: maLable }
	)

	if (!isFilteredBySearch(maData.table)) {
		document.querySelector('#maSelect--all').textContent = interpolate(
			gettext('Alle gefilterten %(objectName)s (%(numberOfFilteredRecords)s)'),
			{ objectName: maLable, numberOfFilteredRecords: recordsDisplay }
		)
	} else {
		document.querySelector('#maSelect--all').textContent = interpolate(
			gettext('Alle gefilterten %(objectName)s'),
			{ objectName: maLable }
		)
	}

	show('.maRecordsTotalWrapper')

	resetMassAction()

	const massSelectionExecuteActionName = document.querySelector(
		'#massSelectionExecuteActionName'
	)
	const maActionSelect = document.querySelector('#maActionSelect')
	const maActionSelectSpan = maActionSelect.querySelector('span')

	maData.action = document.querySelector('#maAction').value
	const maSelect = document.querySelector('#maSelect')
	const maAction = document.querySelector('#maAction')
	const selectedOption = maAction.options[maAction.selectedIndex]

	maActionSelect.removeEventListener('click', startMassAction)
	maActionSelect.removeEventListener('click', showMassActionSelection)
	maActionSelect.disabled = false

	hide('#maWarningSearchActive')
	hide('#maWarning')
	if (maSelect.value === 'all') {
		maData.all = true
		if (isFilteredBySearch(maData.table)) {
			show('#maWarningSearchActive')
			maActionSelect.disabled = true
		} else {
			show('#maWarning')
			maActionSelect.addEventListener('click', startMassAction)

			maActionSelectSpan.innerText = selectedOption.innerText
			hide('#maActionLabelSuffix')
		}
	} else {
		maData.all = false
		hide('#maWarning')
		massSelectionExecuteActionName.innerText = selectedOption.innerText

		maActionSelectSpan.innerText = maLable
		show('#maActionLabelSuffix')

		maActionSelect.addEventListener('click', showMassActionSelection)
	}
	if (maData.action === 'acceptMembershipOffers') {
		show('#aamWarning')
	} else {
		hide('#aamWarning')
	}
}

/**
 * Copies the data from `maData`
 *
 * @returns {object} `maData` copy
 */
function copyData() {
	return {
		action: maData.action,
		collection: maData.collection,
		all: maData.all,
		table: maData.table,
		model: maData.model,
	}
}

/**
 * Triggers a mass action
 *
 * @returns {undefined} - Nothing
 */
async function startMassAction() {
	const data = copyData()
	if (isFilteredBySearch(maData.table) && data.all) return

	// show loader for massActions. Maybe can removed again with implmentation of #9202
	const maAction = document.querySelector('#maAction')
	loader(
		interpolate(
			gettext(
				'Sammelaktion "%(massAction)s" wird vorbereitet. Bitte warten...'
			),
			{ massAction: maAction.options[maAction.selectedIndex].innerText }
		),
		true
	)
	const searchQuery = document.querySelector('input[type=search]')?.value
	if (searchQuery && data?.collection?.length === 0) {
		alertDanger('MAx400xSearchSelected', undefined, {
			shortDescriptionOnly: true,
		})
		return
	}

	data.table = getRecordsDisplay(data.table)

	if (!data.all) {
		// Prevents the following: 'ERROR The number of GET/POST parameters exceeded settings.DATA_UPLOAD_MAX_NUMBER_FIELDS.'
		if (data.collection.length > 900) {
			const limitText = await (
				await getMessageText('MAx000xHandSelectionLimit', '', '', false, true)
			).json()
			const message = `${interpolate(
				gettext('Es sind %(quantity)s Einträge von Hand ausgewählt.'),
				{ quantity: data.collection.length }
			)} ${limitText.msg}`
			alertWarning(undefined, message)
			resetMassAction()
			return
		} else if (!data.collection || data.collection.length === 0) {
			hide('#loading')
			alertInformation('MAx000xNoSelectionByHand')
			return
		}
	}

	const preActionCallback = maPreActionCallbacks[data.action]
	let preActionResults
	if (preActionCallback) {
		try {
			preActionResults = await preActionCallback(data)
		} catch (err) {
			hide('#loading')
			return
		}
	}
	if (preActionResults) data.extra = preActionResults

	if (data.action.includes('delete')) {
		let msg = gettext('Wirklich alle Einträge löschen?')
		if (data.collection) {
			msg = interpolate(
				gettext('Wirklich alle %(quantity)s Einträge löschen?'),
				{ quantity: data.collection.length }
			)
		}
		let confirmation
		if (data.model === 'Invoice' && data.collection) {
			confirmation = await alertWarningConfirmRemovingPath(data.collection, msg)
		} else {
			confirmation = await alertWarningConfirmation(undefined, msg, {
				confirmButtonText: gettext('Wirklich löschen'),
			})
		}
		if (!confirmation.isConfirmed) {
			hide('#loading')
			return
		}
	}

	const response = await fetch('/app/api/massAction/', {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify(data),
	})

	const responseData = await response.json()
	if (responseData.success) {
		const callback = maActionCallbacks[data.action]
		if (callback) callback(responseData)
		toggleMassActionMode()
		if (responseData.isAsync) {
			confirmTaskStarted(responseData.msg, responseData, () => {
				window.location.reload()
			})
		} else if (!callback) {
			showMassActionModal(responseData)
		}
	} else {
		hide('#loading')
		successMessage(responseData)
	}
}

/**
 * Confirming if the user wants to delete the invoice path file as well
 *
 * @param {Array} ids - An array of the ids of the invoices to delete
 * @param {string} msg - The Messagetext
 *
 * @returns {object} - The choice object of the Swal
 */
async function alertWarningConfirmRemovingPath(ids, msg) {
	const removeFileOnDelete = await getMessageText(
		'BKx000xRemoveFileOnDelete',
		'',
		'',
		false,
		true
	)
	const rfod = await removeFileOnDelete.json()
	const config = {
		title: gettext('Bitte bestätigen'),
		icon: 'warning',
		html: `
			<p>${msg}</p>
			<small>
				<div class="form-check form-switch p-0 d-flex align-items-center">
					<input type="checkbox" id="removeFileOnDelete" class="form-check-input ms-4"></input>
					<label for="removeFileOnDelete" class="ps-1 col-10 form-check-label">${rfod.msg}</label>
				</div>
			</small>
		`,
		confirmButtonText: gettext('Wirklich löschen'),
		confirmButtonColor: Colors.Danger,
		showCancelButton: true,
		cancelButtonText: gettext('Abbrechen'),
	}
	const userChoice = await alertCustom(config)
	const entries = []
	for (const id of ids) {
		entries.push({
			id: id,
			removeFileOnDelete: document.querySelector('#removeFileOnDelete').checked,
		})
	}
	if (userChoice.isConfirmed) {
		await patch('invoice/bulk-update', '', { entries })
	}
	return userChoice
}

/**
 * Shows mass action result modal
 *
 * @param {object} data - mass action result data
 * @param {string} loadMore - HTML template string for a load more button or link
 * @param {string} taskId - id of the celery task
 */
function showMassActionModal(data, loadMore = '', taskId = '') {
	if ('state' in data) {
		data = data.details
		data.content = JSON.parse(data.content)
	} else if ('location' in data) {
		window.location = data.location
	}

	let content = ''
	const description = `${gettext('Sammelaktion')}: <strong>"${
		data.description
	}"</strong>`
	const isChargeBackAction = data.description
		.toLowerCase()
		.includes(gettext('Rücklastschriften').toLowerCase())
	const isDuplicationAction = data.description
		.toLowerCase()
		.includes(
			gettext('Mitgliederprofile mit ähnlichen Eigenschaften').toLowerCase()
		)
	let title = ''
	let icon = ''

	if (
		data.done !== data.total ||
		data.content.error ||
		(isChargeBackAction &&
			data.content &&
			data.content.data &&
			data.content.data.length > 0)
	) {
		icon = 'error'
		let doneDuplications
		let removedDuplications

		if (isDuplicationAction) {
			doneDuplications = localStorage.getItem('doneDuplications_' + taskId)
				? JSON.parse(localStorage.getItem('doneDuplications_' + taskId)).value
				: []
			removedDuplications = localStorage.getItem(
				'removedDuplications_' + taskId
			)
				? JSON.parse(localStorage.getItem('removedDuplications_' + taskId))
				: []
		}
		content += '<div class="accordion">'
		for (const error of data.content.data) {
			if (isDuplicationAction) {
				if (
					removedDuplications &&
					removedDuplications.value &&
					removedDuplications.value.includes(error[0].toString())
				) {
					continue
				}
				const markAsDone = doneDuplications.includes(error[0].toString())
				content += '<div class="accordion-item">'
				content += `<div class="accordion-header position-relative py-3" id="heading_${error[0]}">`
				content += `<button class="accordion-button collapsed position-absolute h-100 top-0 start-0 p-0 m-0 z-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_${error[0]}" aria-expanded="false" aria-controls="collapse_${error[0]}"></button>`
				content += `<div id="removeDelete_${error[0]}" class="w-100 text-center">`

				content += '<div class="d-flex justify-content-center">'
				content += `<div class="form-check position-relative"><input type="checkbox" id="inputDuplicate_${
					error[0]
				}" data-id="${
					error[0]
				}" class="markDuplicationAsDone form-check-input" ${
					markAsDone ? 'checked' : ''
				}><label for="inputDuplicate_${
					error[0]
				}" class="form-check-label">${gettext(
					'Mitglied wurde bearbeitet'
				)}</label></div>`
				content += '</div>'

				content += '<div class="d-flex justify-content-center">'
				content += `<div class="form-check position-relative"><input type="checkbox" id="inputDelete_${
					error[0]
				}" data-id="${
					error[0]
				}" class="markDuplicationAsDeleted form-check-input"><label for="inputDelete_${
					error[0]
				}" class="form-check-label">${gettext(
					'Mitglied wurde gelöscht'
				)}</label></div>`
				content += '</div>'

				content += `<div id="highlightDone_${error[0]}">${
					markAsDone ? '<s>' : ''
				}<a href="/app/profile/${
					error[0]
				}" target="_blank" class="position-relative"><strong>${
					error[1]
				}</strong></a> ${error[2]}${markAsDone ? '</s>' : ''}</div>`
			} else {
				content += '<div class="accordion-item">'
				content += '<div>'
				content += `${error[1]}: <code>${error[2]}</code><br>`
				content += '</div>'
			}
		}
		content += '</div>'

		if (data.content.error) {
			title = `${gettext('Die Sammelaktion ist unerwartet abgestürzt')} (${
				data.done
			} von ${data.total})`
			content = `${description} ${gettext(
				'wurde vorzeitig beendet. Folgende Aktionen waren erfolgreich'
			)}:<hr>${content}`
			console.error(data)
		} else if (isChargeBackAction) {
			title = `${gettext('Die Sammelaktion wurde abgeschlossen')} - ${
				data.done
			} von ${data.total} Buchungen verarbeitet, davon wurden ${
				parseInt(data.done) - parseInt(data.softFailures)
			} Buchungen als Rücklastschriften erkannt`
			content = `${description} ${gettext(
				'wurde fertiggestellt. Folgende Buchungen wurden nicht als Rücklastschriften erkannt'
			)}:<hr>${content}`
			console.error(data)
		} else if (isDuplicationAction) {
			title = `${data.done} von ${data.total} ${data.description}:`
		} else {
			title = `${gettext(
				'Die Sammelaktion war nicht vollständig erfolgreich'
			)} (${data.total - data.done} von ${data.total})`
			content = `${description} ${gettext(
				'wurde fertiggestellt. Folgende Aktionen sind gescheitert'
			)}:<hr>${content}`
			console.error(data)
		}
	} else {
		content += `${description} ${gettext('wurde ohne Fehler abgeschlossen')}!`
		icon = 'success'
		title = `${gettext('Die Sammelaktion war erfolgreich')} (${data.done} von ${
			data.total
		})`
	}

	content = loadMore !== '' ? `<br>${content}<hr>${loadMore}` : `<br>${content}`

	const maPaging = document.querySelector('#maPaging')
	if (maPaging) {
		Swal.update({ html: content })
		maPaging.addEventListener('click', (event) => {
			event.preventDefault()
			getAsyncMassActionData(event, false)
		})
	} else {
		const swalSettings = {
			icon: icon,
			title: title,
			html: content,
			customClass: 'swal-large',
			showConfirmButton: true,
			confirmButtonText: gettext('Seite aktualisieren'),
			confirmButtonColor: Colors.Primary,
			showCancelButton: true,
			cancelButtonText: gettext('Schließen'),
		}
		alertCustom(swalSettings).then((result) => {
			if (result.isConfirmed) {
				location.reload()
			}
		})
	}

	for (const cb of document.getElementsByClassName('markDuplicationAsDone')) {
		cb.removeEventListener(
			'click',
			() => {
				markDuplication(cb, 'doneDuplications', taskId)
			},
			true
		)
		cb.addEventListener('click', () => {
			markDuplication(cb, 'doneDuplications', taskId)
		})
	}

	for (const cb of document.getElementsByClassName(
		'markDuplicationAsDeleted'
	)) {
		cb.removeEventListener(
			'click',
			() => {
				markDuplication(cb, 'removedDuplications', taskId)
			},
			true
		)
		cb.addEventListener('click', () => {
			markDuplication(cb, 'removedDuplications', taskId)
		})
	}
}

/**
 * Marks a duplication entry from the deduplication massAction
 *
 * @param {Element} cb - A checkbox HTML element
 * @param {string} type - 'doneDuplications' or 'removedDuplications'
 * @param {string} taskId - The task id of the massAction
 */
function markDuplication(cb, type, taskId) {
	let value = getWithExpiry(`${type}_${taskId}`) || []

	if (cb.checked && !value.includes(cb.dataset.id)) {
		value.push(cb.dataset.id)
	} else if (!cb.checked) {
		value = value.filter((id) => id !== cb.dataset.id)
	}

	setWithExpiry(`${type}_${taskId}`, value, 86400000)

	if (type === 'doneDuplications') {
		const label = document.getElementById(`highlightDone_${cb.dataset.id}`)
		label.innerHTML = label.innerHTML
			.replaceAll('<s>', '')
			.replaceAll('</s>', '')

		if (cb.checked) {
			label.innerHTML = `<s>${label.innerHTML}</s>`
		}
	} else {
		document
			.getElementById(`removeDelete_${cb.dataset.id}`)
			.closest('.accordion-item')
			.remove()
	}
}

/**
 * Retrieves the massaction result
 *
 * @param {Event} e - a click event
 * @param {boolean} resetErrors - reset maErrors before appending new errors
 * @param {string} actionName - the name of the massAction
 */
export function getAsyncMassActionData(
	e,
	resetErrors = true,
	actionName = null
) {
	e.preventDefault()

	let maErrors = []
	if (resetErrors) {
		maErrors = []
	}

	const url = e.currentTarget.href
	const page = e.currentTarget.dataset.page || 1
	const name =
		e.currentTarget.dataset.name?.toLowerCase() || actionName?.toLowerCase()

	let pageUrl = url

	if (url.indexOf('?') !== -1) {
		pageUrl += '&'
	} else {
		pageUrl += '?'
	}

	pageUrl += `page=${page}`

	let taskId = pageUrl?.indexOf('download-task/')
	taskId = pageUrl.substring(taskId + 14, taskId + 14 + 36)

	if (getWithExpiry('removedDuplications_' + taskId)) {
		pageUrl += `&exclude=${JSON.stringify(
			getWithExpiry('removedDuplications_' + taskId)
		)}`
	}

	fetch(pageUrl)
		.then((response) => {
			return response.json()
		})
		.then((data) => {
			let loadMore = ''
			if (data.next_page) {
				loadMore = `<a class="float-end" href="${url}" data-page="${
					data.next_page
				}" data-name="${name}" id="maPaging">${gettext(
					'Mehr laden'
				)}&nbsp;&raquo;</a>`
			}

			data.content = {}
			maErrors = maErrors.concat(data.data)
			data.content.data = maErrors
			delete data.data

			showMassActionModal(data, loadMore, taskId)
		})
}
