import { debounce } from '../utils/debounce'
import { show, hide } from '../utils/helpers/show'
import {
	eventListenerAdd,
	onclickElements,
} from '../utils/helpers/eventHelpers'
import { get } from '../api/crud'
import { gettext } from '../utils/translation'
import { escapeHtml } from '../utils/html'
import {
	addTooltips,
	disposeTooltips,
	disposeTooltipsFromElement,
} from '../utils/bootstrap/tooltip'
import { setLoadingCircle } from '../utils/loader.mjs'
import { Dropdown } from 'bootstrap'

// dict with the prefixes as key, if no prefix fits (none used) then just iterate through (so do all)
const prefixMethods = {
	MITGLIED: {
		endpoint: 'contact-details',
		permission: 'addresses',
		fields: '{id,name,member{id}}',
		page: '/app/members',
	},
	USER: {
		endpoint: 'contact-details',
		permission: 'addresses',
		fields: '{id,name,member{id}}',
		page: '/app/members',
	},
	M: {
		endpoint: 'contact-details',
		permission: 'addresses',
		fields: '{id,name,member{id}}',
		page: '/app/members',
	},
	ADRESSE: {
		endpoint: 'contact-details',
		permission: 'addresses',
		fields: '{id,name,member{id}}',
		page: '/app/addressbook',
	},
	ADDR: {
		endpoint: 'contact-details',
		permission: 'addresses',
		fields: '{id,name,member{id}}',
		page: '/app/addressbook',
	},
	AD: {
		endpoint: 'contact-details',
		permission: 'addresses',
		fields: '{id,name,member{id}}',
		page: '/app/addressbook',
	},
	SEITE: {}, // special case - see searchPages
	PAGE: {}, // special case - see searchPages
	PG: {}, // special case - see searchPages
	EVENT: {
		endpoint: 'event',
		permission: 'events',
		fields: '{id,name}',
		page: '/app/events',
	},
	TERMIN: {
		endpoint: 'event',
		permission: 'events',
		fields: '{id,name}',
		page: '/app/events',
	},
	T: {
		endpoint: 'event',
		permission: 'events',
		fields: '{id,name}',
		page: '/app/events',
	},
	RECHNUNG: {
		endpoint: 'invoice',
		permission: 'bookings',
		fields: '{id,invNumber}',
		page: '/app/bookkeeping/invoices',
	},
	INV: {
		endpoint: 'invoice',
		permission: 'bookings',
		fields: '{id,invNumber}',
		page: '/app/bookkeeping/invoices',
	},
	R: {
		endpoint: 'invoice',
		permission: 'bookings',
		fields: '{id,invNumber}',
		page: '/app/bookkeeping/invoices',
	},
	AUFGABE: {
		endpoint: 'task',
		permission: 'todo',
		fields: '{id,name}',
		page: '/app/todo',
	},
	TASK: {
		endpoint: 'task',
		permission: 'todo',
		fields: '{id,name}',
		page: '/app/todo',
	},
	AG: {
		endpoint: 'task',
		permission: 'todo',
		fields: '{id,name}',
		page: '/app/todo',
	},
	AGENT: {
		endpoint: 'protocol-element',
		permission: 'events',
		fields: '{id,title,protocol{id}}',
		page: '/app/protocols',
	},
	TOP: {
		endpoint: 'protocol-element',
		permission: 'events',
		fields: '{id,title,protocol{id}}',
		page: '/app/protocols',
	},
	PROTOCOL: {
		endpoint: 'protocol',
		permission: 'events',
		fields: '{id,name}',
		page: '/app/protocols',
	},
	SITZUNG: {
		endpoint: 'protocol',
		permission: 'events',
		fields: '{id,name}',
		page: '/app/protocols',
	},
	SI: {
		endpoint: 'protocol',
		permission: 'events',
		fields: '{id,name},',
		page: '/app/protocols',
	},
	VOTING: {
		endpoint: 'voting',
		permission: 'votings',
		fields: '{id,name}',
		page: '/app/votings',
	},
	ABSTIMMUNG: {
		endpoint: 'voting',
		permission: 'votings',
		fields: '{id,name}',
		page: '/app/votings',
	},
	VO: {
		endpoint: 'voting',
		permission: 'votings',
		fields: '{id,name}',
		page: '/app/votings',
	},
	// Smart move ftw: you can define just pages here:
	ÜBERSICHT: { page: '/app/overview' },
	OVERVIEW: { page: '/app/overview' },
	Ü: { page: '/app/overview' },
	WASTEBASKET: { page: '/app/wastebasket' },
	PAPIERKORB: { page: '/app/wastebasket' },
	PK: { page: '/app/wastebasket' },
	PLACE: { page: '/app/locations' },
	ORT: { page: '/app/locations' },
	O: { page: '/app/locations' },
	INVENTORY: { page: '/app/inventory' },
	INVENTAR: { page: '/app/inventory' },
	I: { page: '/app/inventory' },
	MEETING: { page: '/app/meetingroom' },
	DOCUMENT: { page: '/app/bookkeeping/invoices' },
	BELEG: { page: '/app/bookkeeping/invoices' },
	B: { page: '/app/bookkeeping/invoices' },
	DATEI: { page: '/app/share/' },
	FILE: { page: '/app/share/' },
	F: { page: '/app/share/' },
	logout: { page: '/app/logout' },
	42: { page: 'https://www.youtube.com/watch?v=VUWv2GGii4s' }, // easter egg
	lol: { page: 'https://youtu.be/dQw4w9WgXcQ' }, // easter egg
	easyegg: { page: 'https://easyverein.rocks/app' }, // easy egg
}
const endpointPages = {
	'contact-details': ['/app/addressbook?id={id}', '/app/profile/{id}'],
	invoice: '/app/bookkeeping/invoice/{id}',
	task: '/app/todo?id={id}',
	event: '/app/events?id={id}',
	'protocol-element': '/app/protocol/{id}',
	protocol: '/app/protocol/{id}',
	voting: ['/app/voting/{id}', '/app/votings?id={id}'],
	page: '{id}',
}
let searchTerm

const pages = {
	// TODO vllt. mal die ganzen Seiten rein geben statt hardcoden
	// TODO vllt. auch übersetzen?
	// page: [searchterm, searchterm, designation] - The last one is allways the designation
	'/app/overview': {
		icon: 'fa-chart-line',
		searchTerms: [
			'haupt',
			'start',
			'dashboard',
			'amaturenbrett',
			'vereinsübersicht',
		],
	},
	'https://hilfe.easyverein.com': {
		icon: 'fa-life-ring',
		searchTerms: ['help', '?', 'support', 'hilfe'],
	},
	'/app/members': {
		icon: 'fa-users',
		searchTerms: ['member', 'profile', 'verwalten', 'mitgliederübersicht'],
	},
	'/app/addressbook': {
		icon: 'fa-address-book',
		searchTerms: ['addresses', 'profile', 'verwalten', 'adressübersicht'],
	},
	'/app/addressbook/mailings': {
		icon: 'fa-envelope',
		searchTerms: ['email', 'smtp', 'serienbriefe'],
	},
	'/app/events': {
		icon: 'fa-calendar-alt',
		searchTerms: [
			'kalender',
			'calendar',
			'events',
			'veranstaltungen',
			'termine',
		],
	},
	'/app/protocols': {
		icon: 'fa-list',
		searchTerms: [
			'protocols',
			'top',
			'tagesordnungspunkte',
			'sitzung',
			'protokolle',
		],
	},
	'/app/todo': {
		icon: 'fa-clipboard-list',
		searchTerms: ['todo', 'task', 'aufgaben'],
	},
	'/app/bookkeeping': {
		icon: 'fa-money-bill-wave',
		searchTerms: ['bookings', 'bookkeeping', 'buchhaltung'],
	},
	'/app/bookkeeping/invoices': {
		icon: 'fa-money-bill-wave',
		searchTerms: ['invoices', 'belege', 'rechnungen'],
	},
	'/app/bookkeeping/statistics': {
		icon: 'fa-chart-line',
		searchTerms: ['statistik', 'finanzübersicht'],
	},
	'/app/bookkeeping/invoices/add/': {
		icon: 'fa-pencil',
		searchTerms: ['neu', 'belege', 'rechnung anlegen'],
	},
	'/app/bookkeeping/donation/certificates': {
		icon: 'fa-file-certificate',
		searchTerms: ['certificates', 'zuwendungsbescheinigung'],
	},
	'/app/import/Booking': {
		icon: 'fa-file-alt',
		searchTerms: ['buchungen', 'buchungsimport'],
	},
	'/app/import/User': {
		icon: 'fa-file-alt',
		searchTerms: ['mitglieder', 'mitgliedsimport'],
	},
	'/app/import/Address': {
		icon: 'fa-file-alt',
		searchTerms: ['adressen', 'adressimport'],
	},
	'/app/locations': {
		icon: 'fa-map-marker-alt',
		searchTerms: ['räume', 'raum', 'plätze', 'platz', 'locations', 'orte'],
	},
	'/app/locations#tab_Reservations': {
		icon: 'fa-calendar-alt',
		searchTerms: ['verfügbarkeit', 'reservierungen'],
	},
	'/app/inventory': {
		icon: 'fa-cube',
		searchTerms: ['inventory', 'gegenstand', 'ausleihen', 'inventar'],
	},
	'/app/organization': {
		icon: 'fa-users',
		searchTerms: ['standardprofilbild', 'logo', 'anpassen', 'vereinsdaten'],
	},
	'/app/profile': {
		icon: 'fa-user',
		searchTerms: ['meine', 'ich', 'me', 'passwort', 'account', 'profildaten'],
	},
	'/app/settings': {
		icon: 'fa-cog',
		searchTerms: [
			'vereinseinstellungen',
			'darstellung',
			'zurücksetzen',
			'systemeinstellungen',
		],
	},
	'/app/settings/#tab_a': {
		icon: 'fa-cog',
		searchTerms: ['deaktivieren', 'module'],
	},
	'/app/settings/#tab_w': {
		icon: 'fa-cog',
		searchTerms: ['webseite', 'eigene links'],
	},
	'/app/settings/#tab_b': {
		icon: 'fa-cog',
		searchTerms: ['forum', 'erlauben', 'mitgliederzugriff'],
	},
	'/app/settings/#tab_c': {
		icon: 'fa-cog',
		searchTerms: ['ausweise', 'mitgliedschaftsbescheinigungen'],
	},
	'/app/settings/#tab_m': {
		icon: 'fa-cog',
		searchTerms: ['email', 'smtp', 'smtp einstellungen'],
	},
	'/app/settings/#tab_n': { icon: 'fa-cog', searchTerms: ['email vorlagen'] },
	'/app/settings/cloud': {
		icon: 'fa-cog',
		searchTerms: ['einstellungen', 'dateispeicher'],
	},
	'/app/settings/invoices': {
		icon: 'fa-cog',
		searchTerms: [
			'buchhaltung',
			'bankkonto',
			'beiträge',
			'finanzeinstellungen',
		],
	},
	'/app/settings/invoices/#tab_skr': {
		icon: 'fa-cog',
		searchTerms: ['währungen', 'buchungskont', 'steuersätze', 'konten'],
	},
	'/app/settings/invoices/#tab_ic': {
		icon: 'fa-cog',
		searchTerms: ['zahlungserinnerungen', 'mahnwesen'],
	},
	'/app/settings/invoices/#tab_c': {
		icon: 'fa-cog',
		searchTerms: ['zuwendungsbescheinigung'],
	},
	'/app/settings/invoices/#tab_cb': {
		icon: 'fa-cog',
		searchTerms: ['rücklastschriften'],
	},
	'/app/settings/invoices/#tab_ob': {
		icon: 'fa-cog',
		searchTerms: ['anbindung', 'online banking'],
	},
	'/app/settings/groups': {
		icon: 'fa-cog',
		searchTerms: ['verwalten', 'admin', 'gruppeneinstellungen'],
	},
	'/app/settings/groups/#tab_ca': {
		icon: 'fa-cog',
		searchTerms: ['calendar', 'kalender'],
	},
	'/app/settings/integrations': {
		icon: 'fa-cog',
		searchTerms: [
			'dropbox',
			'cleverreach',
			'süderelbe',
			'inkasso',
			'online',
			'dosb',
			'steuerberater',
			'anbindung',
			'integrationen',
		],
	},
	'/app/settings/easyVereinApi/': { icon: 'fa-cog', searchTerms: ['api'] },
	'/app/settings/integrations/#datev-export': {
		icon: 'fa-cog',
		searchTerms: ['datev'],
	},
	'/app/settings/integrations/#unidy-sso': {
		icon: 'fa-cog',
		searchTerms: ['unidy'],
	},
	'/app/settings/integration-twingle/': {
		icon: 'fa-cog',
		searchTerms: ['spenden', 'twingle'],
	},
	'/app/settings/app/': { icon: 'fa-cog', searchTerms: ['smartphone', 'app'] },
	'/app/settings/personal': {
		icon: 'fa-cog',
		searchTerms: [
			'verwalten',
			'eigene',
			'selber',
			'dark',
			'dunkel',
			'faktor',
			'2fa',
			'smtp',
			'hintergrund',
			'meine einstellungen',
		],
	},
	'/app/license': {
		icon: 'fa-file-certificate',
		searchTerms: [
			'verein',
			'aufwerten',
			'upgrade',
			'anpassen',
			'werben',
			'empfehlung',
			'lizenzverwaltung',
		],
	},
	'/app/wastebasket': {
		icon: 'fa-trash',
		searchTerms: ['wastebasket', 'mülleimer', 'leeren', 'papierkorb'],
	},
	'/app/settings/fields': {
		icon: 'fa-cog',
		searchTerms: ['custom', 'individuelle felder'],
	},
	'/app/meetingroom': {
		icon: 'fa-video',
		searchTerms: [
			'meeting',
			'video',
			'übertragung',
			'konferenz',
			'besprechungen',
		],
	},
	'/app/share': {
		icon: 'fa-share',
		searchTerms: ['hochladen', 'runter', 'dateiverwaltung'],
	},
	'/features': {
		icon: 'fa-lightbulb',
		searchTerms: ['feature', 'vorschlag', 'ideencommunity'],
	},
	'/app/logs': { icon: 'fa-clock', searchTerms: ['log', 'änderungsprotokoll'] },
	'/app/loginlogs': {
		icon: 'fa-clock',
		searchTerms: ['log', 'anmeldeprotokoll'],
	},
	'/app/emaillogs': {
		icon: 'fa-clock',
		searchTerms: ['log', 'emailprotokoll'],
	},
	'/app/integrity': {
		icon: 'fa-clock',
		searchTerms: [
			'korrektur',
			'überprüfen',
			'datev',
			'automatisch',
			'prüfen und ändern',
		],
	},
	'/app/safetyproperties': {
		icon: 'fa-cog',
		searchTerms: ['passworteinstellungen'],
	},
	'/app/safetyproperties/#tab_l': {
		icon: 'fa-cog',
		searchTerms: [
			'aufbewarung',
			'protokolle',
			'papierkorb',
			'log',
			'verlaufseinstellungen',
		],
	},
	'/app/safetyproperties/#tab_f': {
		icon: 'fa-cog',
		searchTerms: ['2fa', 'faktor', 'authentifizierung'],
	},
	'/app/settings/website': {
		icon: 'fa-cog',
		searchTerms: [
			'login',
			'widget',
			'logo',
			'embed',
			'webseite',
			'anmeldeeinbindung',
		],
	},
	'/app/settings/applicationform': {
		icon: 'fa-cog',
		searchTerms: ['aufnahmeformular'],
	},
	'/app/votings/': {
		icon: 'fa-vote-yea',
		searchTerms: ['votings', 'umfragen', 'abstimmungen'],
	},
	'/app/settings/integration-passcreator': {
		icon: 'fa-cog',
		searchTerms: ['ausweise', 'rabattkarten', 'passcreator'],
	},
	'/app/statistics/members/': {
		icon: 'fa-chart-line',
		searchTerms: ['mitgliederstatistik'],
	},
	'/app/statistics/addresses/': {
		icon: 'fa-chart-line',
		searchTerms: ['adressstatistik'],
	},
	'/app/statistics/inventory/': {
		icon: 'fa-chart-line',
		searchTerms: ['inventarstatistik'],
	},
	'/app/settings/reference/': {
		icon: 'fa-comment',
		searchTerms: ['feedback', 'rückmeldung', 'weiterempfehlen'],
	},
	'/app/benefits/': {
		icon: 'fa-percentage',
		searchTerms: ['partner', 'preisvorteile'],
	},
	'/app/training/': {
		icon: 'fa-graduation-cap',
		searchTerms: ['beratung', 'schulungen'],
	},
	'/app/lawandcontracts/': {
		icon: 'fa-balance-scale',
		searchTerms: ['datenschutz', 'dsgvo'],
	},
	'/app/settings/documenttemplates/': {
		icon: 'fa-file-pdf',
		searchTerms: ['briefvorlagen', 'rechnungsvorlagen', 'dokumentenvorlagen'],
	},
}

// The prefix is valid, the prefix is something like this 'AD: $userinput'
const validPrefix = (value) => {
	return Boolean(
		Object.keys(prefixMethods).find((prefix) => value.startsWith(`${prefix}:`))
	)
}
// The value matches exactly one of the prefixes, its something like this 'AD'
// Not used in the current state - maybe become usefull sometime
// const matchKey = (value) => { return Boolean(Object.keys(prefixMethods).find(prefix => value === prefix)) }

let searchBarOldValue = '' // cache for the searchbar

/**
 * Init the search function of easyVerein UI
 */
export function initSearch() {
	onclickElements({
		'.prefixSearch': (event) => setPrefix(event),
	})

	eventListenerAdd(
		{
			'#headerSearchbar': () => toggleDefaultSearchOptions(),
		},
		'focus'
	)

	eventListenerAdd(
		{
			'#headerSearchbar': (event) => {
				debounce(searchEasyVerein, 500)()
				deFocus(event)
			},
		},
		'keyup'
	)

	eventListenerAdd(
		{
			'#headerSearchbar': (event) => redirectSearch(event),
		},
		'keydown'
	)

	eventListenerAdd(
		{
			'#headerSearchbarDropdown': (event) => preventCloseOnFocus(event),
		},
		'hide.bs.dropdown'
	)

	_applyPermissions()
}

/**
 * Defocus the search if esc is pressed
 *
 * @param {Event} event - Default JS onkeyup event object
 */
function deFocus(event) {
	if (event.key === 'Escape') {
		const input = event.currentTarget
		input.blur()
	}
	Dropdown.getOrCreateInstance('#headerSearchbarDropdown').hide()
}

/**
 * Checking the permissions of the user to disable the enire search or some segemnts of the search
 */
async function _applyPermissions() {
	const response = await get(
		'member/me/permissions',
		'',
		{},
		undefined,
		undefined,
		undefined,
		false
	)
	const userpermissions = response.data
	if (response.status !== 200 || !userpermissions._isChairman) {
		// The user is not permitted to perform the easyverein search
		const headerSearchbar = document.querySelector('#headerSearchbar')
		headerSearchbar.disabled = true
		// Rerender the whole input (searchbar) to remove the event listeners
		headerSearchbar.parentNode.innerHTML += ''
	} else {
		for (const prefixes of Object.keys(prefixMethods)) {
			const permission = prefixMethods[prefixes].permission
			prefixMethods[prefixes].permission =
				userpermissions[`module_${permission}`] !== 'n'
		}
	}
}

/**
 * Preventing the closing of the dropdown if the focus is in the search field (only close the dropdown on focus loss)
 *
 * @param {Event} event - The event object of the hide.bs.dropdown event
 */
function preventCloseOnFocus(event) {
	const dropDownMenuElement = document.querySelector('#searchEasyVerein')
	const searchInputElement = document.querySelector('#headerSearchbar')
	const activeElement = document.activeElement
	if (
		dropDownMenuElement.classList.contains('show') &&
		activeElement === searchInputElement
	) {
		event.preventDefault()
	}
}

/**
 * If the entered prefix is known and the user presses enter, redirect to the page
 *
 * @param {Event} event - Default onkeydown JS event object
 */
function redirectSearch(event) {
	if (event.key === 'Enter') {
		const prefix = document.querySelector('#headerSearchbar').value
		const validPrefix = prefixMethods[prefix]
		event.preventDefault()
		if (validPrefix) {
			window.location = validPrefix.page
		} else {
			const firstGuess = document.querySelector('#searchOptions .dropdown-item')
			if (firstGuess && firstGuess.href) {
				window.location = firstGuess.href
			}
		}
	}
}

/**
 * Toggle the visibility of the default search options help
 *
 * @returns {boolean} - true if the options are hidden else false
 */
function toggleDefaultSearchOptions() {
	const headerSearchbar = document.querySelector('#headerSearchbar')
	const value = headerSearchbar.value

	// If the value is only the prefix, show the defaultSearchOptions and do nothing
	if (validPrefix(value) && value.split(':')[1] === '') {
		show('#defaultSearchOptions')
		return false
	}

	if (value !== '') {
		hide('#defaultSearchOptions')
		return true
	} else {
		show('#defaultSearchOptions')
		return false
	}
}

/**
 * Set the clicked prefix as value of the search input
 *
 * @param {Event} event - Default JS onclick event object
 */
function setPrefix(event) {
	event.preventDefault()
	const element = event.currentTarget
	const prefix = element.dataset.prefix
	if (prefix) {
		document.querySelector('#headerSearchbar').value = prefix
	}
	document.querySelector('#headerSearchbar').focus()
}

/**
 * Search for a specific prefix or for all using the API
 */
async function searchEasyVerein() {
	const headerSearchbar = document.querySelector('#headerSearchbar')
	const value = headerSearchbar.value
	if (value && value === searchBarOldValue) return // Do nothing if the input has not changed
	searchBarOldValue = value
	// If nothing to search for is in the searchbar just do nothing (wait until user input)
	if (toggleDefaultSearchOptions()) {
		disposeTooltips('#searchOptions [data-bs-toggle="tooltip"]')
		setLoadingCircle(document.querySelector('#searchOptions'))
		let endpointIdNameMemberId = {}
		searchTerm = value
		const prefixes = Object.keys(prefixMethods)
		if (value && validPrefix(value)) {
			// Search only for one prefix
			const prefixKey = value.split(':')[0]
			searchTerm = value.substr(prefixKey.length, value.length).replace(':', '')
			// If the prefix is SEITE search only through pages
			if (['SEITE', 'PAGE', 'PG'].includes(prefixKey)) {
				endpointIdNameMemberId = {
					page: { results: searchPages(searchTerm), more: false, fields: '-' },
				}
			} else {
				const searchMethod = prefixMethods[prefixKey]
				if (!searchMethod.endpoint)
					endpointIdNameMemberId = {} // If there is no endpoint
				else
					endpointIdNameMemberId = await fillEndpointIdNameMemberId(
						endpointIdNameMemberId,
						searchMethod,
						searchTerm
					)
			}
		} else {
			// Search all
			const endpoints = [] // count the endpoints that are allready used to use any endpoint only once
			for (const prefix of prefixes) {
				const searchMethod = prefixMethods[prefix]
				if (
					endpoints.includes(searchMethod.endpoint) ||
					!searchMethod.endpoint ||
					!searchMethod.permission
				)
					continue // Save the iteration if the endpoint was allready used, if there is no endpoint
				endpointIdNameMemberId = await fillEndpointIdNameMemberId(
					endpointIdNameMemberId,
					searchMethod,
					searchTerm
				)
				endpoints.push(searchMethod.endpoint)
			}
			endpointIdNameMemberId.page = {
				results: searchPages(searchTerm),
				more: false,
				fields: '-',
			}
		}
		buildSearchOptions(endpointIdNameMemberId)
	} else {
		document.querySelector('#searchOptions').innerHTML = ''
	}
}

/**
 * Search for the search term using the API
 *
 * @param {object} endpointIdNameMemberId - An object containing the endpoint, id, name and memberId (if it is an adress with a linked member)
 * @param {object} searchMethod - An object containing the endpoint and fields to use for searching using the API
 * @param {string} searchTerm - The search term to query
 * @param {number} limit - (Optional) The limit of entries to get
 * @returns {object} - An object that contains the used endpoint, id, name and memberId
 */
async function fillEndpointIdNameMemberId(
	endpointIdNameMemberId,
	searchMethod,
	searchTerm,
	limit = 5
) {
	const response = await get(
		searchMethod.endpoint,
		searchMethod.fields,
		{ deleted: false, search: searchTerm },
		limit,
		1,
		'',
		false
	)
	if (response.status === 200 && response.data?.results) {
		const results = converteResults(response.data.results)
		endpointIdNameMemberId[searchMethod.endpoint] = {
			results: results,
			more: response.data.count > 5,
			fields: searchMethod.fields,
		}
	}

	return endpointIdNameMemberId
}

/**
 * Converte the results to allways have the same keys
 *
 * @param {Array} results - An Array containing the results
 * @returns {Array} - The results but with allways the same keys
 */
function converteResults(results) {
	return results.map((entry) => {
		return {
			id: entry.protocol?.id || entry.id,
			name:
				entry.name || entry.invNumber || entry.title || gettext('unbekannt'),
			memberId: entry?.member?.id,
		}
	})
}

/**
 * Build the search options for the dropdown in html
 *
 * @param {object} endpointIdNameMemberId - An object containing the endpoint, id, name and memberId (if it is an adress with a linked member)
 */
function buildSearchOptions(endpointIdNameMemberId) {
	let conclusionHTML = ''
	Object.keys(endpointIdNameMemberId).forEach((endpoint) => {
		const { results, more, fields } = endpointIdNameMemberId[endpoint]
		conclusionHTML += `<span id="category-${endpoint}">`
		conclusionHTML += buildCategory(endpoint, results, fields, more)
		conclusionHTML += '</span>'
	})
	if (!conclusionHTML.includes('li'))
		conclusionHTML = `<li><a class="dropdown-item disabled">${gettext(
			'Kein Eintrag oder Seite gefunden'
		)}</a></li>`
	document.querySelector('#searchOptions').innerHTML = conclusionHTML
	addTooltips('#searchOptions [data-bs-toggle="tooltip"]')

	onclickElements({
		'.searchForMore': (event) => searchForMore(event),
	})
}

/**
 * Query more search results for the category that is clicked
 *
 * @param {Event} event - Default JS onclick event object
 */
async function searchForMore(event) {
	const element = event.currentTarget
	const endpoint = element.dataset.endpoint
	const fields = element.dataset.fields

	disposeTooltipsFromElement(element)
	setLoadingCircle(element.parentNode)
	document.querySelector('#headerSearchbar').focus()

	const endpointIdNameMemberId = await fillEndpointIdNameMemberId(
		{},
		{ endpoint, fields },
		searchTerm,
		20
	)
	const endpointReturnDict = endpointIdNameMemberId[endpoint]
	document.querySelector(`#category-${endpoint}`).innerHTML = buildCategory(
		endpoint,
		endpointReturnDict.results,
		endpointReturnDict.fields,
		false
	)

	addTooltips(`#category-${endpoint} [data-bs-toggle="tooltip"]`)
}

/**
 * Build a single categorie of search option
 *
 * @param {string} endpoint - The API endpoint that was used
 * @param {Array} results - The returned entries of the API or page entries
 * @param {string} fields - A string containing the fields to query for the endpoint - only used if more is true
 * @param {boolean} more - A bloolean that is true if there are more entries than presented (show the more-option)
 * @returns {string} - The string that is build for one category of search options to be in the innerHTML of the searchOptions
 */
function buildCategory(endpoint, results, fields, more) {
	let category = buildHeader('', endpoint, results)
	const currentPageHref = window.location.href
	results.forEach((entry) => {
		let name = entry.name
		// reduce the amount of chars in a name so the search result-overlap doesn't get too big
		if (name.length > 23) {
			name = `${name.slice(0, 23)}...`
		}
		name = escapeHtml(name)
		const icon = entry.icon ? `<i class="far ${entry.icon}"></i> ` : ''
		if (endpoint === 'contact-details') {
			// members
			const addressLink = `<a class="dropdown-item" href="${endpointPages[
				endpoint
			][0].replace(
				'{id}',
				entry.id
			)}" data-bs-toggle="tooltip" title="${gettext(
				'Zur Adresse'
			)}">${name}</a>`
			const memberLink = entry.memberId
				? `<a class="dropdown-item w-auto" href="${endpointPages[
						endpoint
				  ][1].replace(
						'{id}',
						entry.memberId
				  )}" data-bs-toggle="tooltip" title="${gettext(
						'Zum verknüpften Mitglied'
				  )}"><i class="far fa-fw fa-link text-primary"></i></a>`
				: ''
			category += `<li class="d-flex">${addressLink} ${memberLink}</li>`
		} else if (endpoint === 'voting') {
			const votingLink = `<a class="dropdown-item" href="${endpointPages[
				endpoint
			][0].replace(
				'{id}',
				entry.id
			)}" data-bs-toggle="tooltip" title="${gettext(
				'Zur Abstimmung'
			)}">${name}</a>`
			const votingResults = `<a class="dropdown-item w-auto" href="${endpointPages[
				endpoint
			][1].replace(
				'{id}',
				entry.id
			)}" data-bs-toggle="tooltip" title="${gettext(
				'Zum Ergebnis'
			)}"><i class="far fa-poll text-primary"></i></a>`
			category += `<li class="d-flex">${votingLink} ${votingResults}</li>`
		} else {
			// everything except members and voting
			const href = endpointPages[endpoint].replace('{id}', entry.id)
			// Ignore the current page for the page search
			if (endpoint !== 'page' || !currentPageHref.includes(href)) {
				category += `<li><a class="dropdown-item" href="${href}">${icon}${name}</a></li>`
			}
		}
	})
	if (more) {
		category += `<li><a class="dropdown-item text-center searchForMore" href="#!" data-endpoint="${endpoint}" data-fields=${fields} data-bs-toggle="tooltip" title="${gettext(
			'Mehr laden'
		)}"><i class="far fa-angle-down"></i></a></li>`
	}
	return category
}

/**
 * Build the header for the search options
 *
 * @param {string} conclusionHTML - The string that is build to be the innerHTML of the searchOptions
 * @param {string} endpoint - The API endpoint that was used
 * @param {Array} results - The returned entries of the API
 * @returns {string} - The string that is build to be the innerHTML of the searchOptions
 */
function buildHeader(conclusionHTML, endpoint, results) {
	// Get the header of the options
	// If the memberId is null/undefined but the endpoint is contact-details it is an adress, else an address with linked member
	const header = {
		'contact-details':
			results.filter((entry) => ![undefined, null].includes(entry.memberId))
				.length > 0
				? gettext('Adressen & Mitglieder')
				: gettext('Adressen'),
		invoice: gettext('Rechnungen'),
		task: gettext('Aufgaben'),
		event: gettext('Termine'),
		'protocol-element': gettext('Tagesordnungspunkte'),
		protocol: gettext('Sitzungen'),
		voting: gettext('Abstimmungen'),
		page: gettext('Seiten'),
	}[endpoint]
	conclusionHTML +=
		results.length > 0
			? `<li><h1 class="dropdown-header fw-bolder">${header}:</h1></li>`
			: ''
	return conclusionHTML
}

/**
 * Search through all pages
 *
 * @param {string} searchTerm - The search term to query
 * @returns {Array} - An array containing the objects of the pages matching the searchTerm
 */
function searchPages(searchTerm) {
	const searchegPages = []
	searchTerm = searchTerm.toLowerCase().replace(' ', '')
	Object.keys(pages).forEach((page) => {
		const possibleTerms = pages[page].searchTerms
		const icon = pages[page].icon
		if (
			possibleTerms.filter(
				(possibleTerm) =>
					searchTerm.includes(possibleTerm) || possibleTerm.includes(searchTerm)
			).length > 0
		) {
			const name = possibleTerms[possibleTerms.length - 1]
			const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
			searchegPages.push({ id: page, name: nameCapitalized, icon: icon })
		}
	})
	return searchegPages
}
