import { getContextData } from './django'

const { gettext } = require('./translation')

const overallPlaceholder = [
	[
		'%heute%',
		gettext('Wird durch das heutige Datum ersetzt im Format "TT.MM.JJJJ"'),
	],
	[
		'%tag_heute%',
		gettext('Wird durch den heutigen Tag ersetzt im Format "TT"'),
	],
	[
		'%monat_heute%',
		gettext('Wird durch den aktuellen Monat ersetzt im Format "MM"'),
	],
	[
		'%jahr_heute%',
		gettext('Wird durch das aktuelle Jahr ersetzt im Format "JJJJ"'),
	],
	[
		'%wochentag_heute%',
		gettext(
			'Wird durch den heutigen Wochentag ersetzt ("Montag", "Dienstag", etc.)'
		),
	],
	[
		'%wochentag_kurz_heute%',
		gettext(
			'Wird durch die Abkürzkung des heutigen Wochentags ersetzt ("Mo", "Di", etc.)'
		),
	],
	[
		'%monatsname_heute%',
		gettext(
			'Wird durch den aktuellen Monatsnamen ersetzt ("Januar", "Februar", etc.)'
		),
	],
	[
		'%monatsname_kurz_heute%',
		gettext(
			'Wird durch die Abkürzkung des aktuellen Monats ersetzt ("Jan", "Feb", etc.)'
		),
	],
	[
		'%jahr_kurz_heute%',
		gettext(
			'Wird durch die Kurzform des aktuellen Jahres ersetzt im Format "JJ"'
		),
	],
	[
		'%in_X_tagen%',
		gettext('Das "X" manuell ersetzen durch die gewünschte Anzahl an Tagen'),
	],
	[
		'%in_X_wochen%',
		gettext('Das "X" manuell ersetzen durch die gewünschte Anzahl an Wochen'),
	],
	[
		'%in_X_monaten%',
		gettext('Das "X" manuell ersetzen durch die gewünschte Anzahl an Monaten'),
	],
	[
		'%in_X_jahren%',
		gettext('Das "X" manuell ersetzen durch die gewünschte Anzahl an Jahren'),
	],
	// The following placeholders were replaced by the ones above
	// ['%monat+X%', gettext('Wird durch den aktuellen Monat + "X" Monate ersetzt')],
	// ['%monat-X%', gettext('Wird durch den aktuellen Monat + "X" Monate ersetzt')],
	// ['%jahr+X%', gettext('Wird durch das aktuelle Jahr + "X" Jahre ersetzt')],
	// ['%jahr-X%', gettext('Wird durch den aktuelle Jahr  + "X" Jahre ersetzt')],
	['%box%', gettext('Wird durch eine leeres Rechteck ersetzt')],
	['%logo%', gettext('Wird durch das Vereinslogo ersetzt (mittig)')],
	['%logo_r%', gettext('Wird durch das Vereinslogo ersetzt (rechts)')],
	['%logo_l%', gettext('Wird durch das Vereinslogo ersetzt (links)')],
]

// organization
const organizationPlaceholder = [
	['%vereinsname%', gettext('Wird durch den Namen des Vereins ersetzt')],
	['%vereinskuerzel%', gettext('Wird durch das Kürzel des Vereins ersetzt')],
	['%strasse_verein%', gettext('Wird durch die Straße des Vereins ersetzt')],
	['%stadt_verein%', gettext('Wird durch die Stadt des Vereins ersetzt')],
	[
		'%telefon_verein%',
		gettext('Wird durch die Telefonnummer des Vereins ersetzt'),
	],
	['%email_verein%', gettext('Wird durch die E-Mail des Vereins ersetzt')],
	['%webseite_verein%', gettext('Wird durch die Webseite des Vereins ersetzt')],
	[
		'%steuernummer_verein%',
		gettext('Wird durch die Steuernummer des Vereins ersetzt'),
	],
	[
		'%umsatzsteuernr_verein%',
		gettext('Wird durch die Umsatzsteuernummer des Vereins ersetzt'),
	],
	[
		'%amtsgericht%',
		gettext('Wird durch das zuständige Amtsgericht des Vereins ersetzt'),
	],
	[
		'%registernummer%',
		gettext('Wird durch die Registernummer des Vereins ersetzt'),
	],
	['%iban_verein%', gettext('Wird durch die IBAN des Vereins ersetzt')],
	['%bic_verein%', gettext('Wird durch die BIC des Vereins ersetzt')],
	[
		'%glaeubiger_id%',
		gettext('Wird durch die Gläubiger-ID des Vereins ersetzt'),
	],
	['%waehrung%', gettext('Wird durch die Währung des Vereins ersetzt')],
	[
		'%start_gesch_jahr%',
		gettext('Wird durch das Datum des Starts des Geschäftsjahrs ersetzt'),
	],
	['%zweck_verein%', gettext('Wird durch den Zweck des Vereins ersetzt')],
	[
		'%datum_freist_bescheid%',
		gettext('Wird durch das Datum des Freistellungsbescheids ersetzt'),
	],
	[
		'%zustaendiges_finanzamt%',
		gettext('Wird durch das zuständige Finanzamt des Vereins ersetzt'),
	],
	[
		'%datum_bescheid%',
		gettext(
			'Wird durch das Datum des Freistellungsbescheides des Finanzamts ersetzt'
		),
	],
]

// addresses and members
const addressPlaceholder = [
	[
		'%briefanrede%',
		gettext('Wenn Anrede') +
			' "' +
			gettext('Herr') +
			'": ' +
			gettext('Sehr geehrter Herr %titel% %nachname%') +
			'<br>' +
			gettext('Wenn Anrede') +
			' "' +
			gettext('Frau') +
			'": ' +
			gettext('Sehr geehrte Frau %titel% %nachname%') +
			'<br>' +
			gettext('Sonst') +
			': ' +
			gettext('Sehr geehrte Damen und Herren'),
	],
	[
		'%briefanrede2%',
		gettext('Wenn Anrede') +
			' "' +
			gettext('Herr') +
			'": ' +
			gettext('Lieber %vorname%') +
			'<br>' +
			gettext('Wenn Anrede') +
			' "' +
			gettext('Frau') +
			'": ' +
			gettext('Liebe %vorname%') +
			'<br>' +
			gettext('Sonst') +
			': ' +
			gettext('Hallo'),
	],
	[
		'%briefanrede3%',
		gettext('Wenn Anrede') +
			' "' +
			gettext('Herr') +
			'": ' +
			gettext('Lieber Herr %nachname%') +
			'<br>' +
			gettext('Wenn Anrede') +
			' "' +
			gettext('Frau') +
			'": ' +
			gettext('Liebe Frau %nachname%') +
			'<br>' +
			gettext('Sonst') +
			': ' +
			gettext('Hallo'),
	],
	[
		'%briefanrede4%',
		gettext('Wenn Anrede') +
			' "' +
			gettext('Herr') +
			'": ' +
			gettext('Lieber Herr %titel% %nachname%') +
			'<br>' +
			gettext('Wenn Anrede') +
			' "' +
			gettext('Frau') +
			'": ' +
			gettext('Liebe Frau %titel% %nachname%') +
			'<br>' +
			gettext('Sonst') +
			': ' +
			gettext('Hallo'),
	],
	[
		'%anrede_anschrift%',
		gettext('Wenn Anrede') +
			' "' +
			gettext('Herr') +
			'": ' +
			gettext('Herrn %nachname%') +
			'<br>' +
			gettext('Wenn Anrede') +
			' "' +
			gettext('Frau') +
			'": ' +
			gettext('Frau %nachname%') +
			'<br>' +
			gettext('Sonst') +
			': %anrede%',
	],
	['%anrede%', gettext('Wird durch die Anrede des Empfängers ersetzt')],
	['%titel%', gettext('Wird durch den Titel des Empfängers ersetzt')],
	['%vorname%', gettext('Wird durch den Vornamen des Empfängers ersetzt')],
	['%nachname%', gettext('Wird durch den Nachnamen des Empfängers ersetzt')],
	['%geburtstag%', gettext('Wird durch den Geburtstag des Empfängers ersetzt')],
	['%email%', gettext('Wird durch die primäre E-Mail des Empfängers ersetzt')],
	[
		'%email_login%',
		gettext('Wird durch die Login E-Mail des Empfängers ersetzt'),
	],
	[
		'%email_priv%',
		gettext('Wird durch die private E-Mail des Empfängers ersetzt'),
	],
	[
		'%email_gesch%',
		gettext('Wird durch die geschäftliche E-Mail des Empfängers ersetzt'),
	],
	[
		'%telefon_priv%',
		gettext('Wird durch die private Telefonnummer des Empfängers ersetzt'),
	],
	[
		'%telefon_gesch%',
		gettext(
			'Wird durch die geschäftliche Telefonnummer des Empfängers ersetzt'
		),
	],
	[
		'%telefon_mobil%',
		gettext('Wird durch die mobile Telefonnummer des Empfängers ersetzt'),
	],
	['%position%', gettext('Wird durch die Position des Empfängers ersetzt')],
	['%firma%', gettext('Wird durch die Firma des Empfängers ersetzt')],
	['%strasse%', gettext('Wird durch die Straße des Empfängers ersetzt')],
	[
		'%stadt%',
		gettext('Wird durch Postleitzahl und Stadt des Empfängers ersetzt'),
	],
	['%plz%', gettext('Wird durch Postleitzahl des Empfängers ersetzt')],
	['%ort%', gettext('Wird durch die Stadt des Empfängers ersetzt')],
	[
		'%adresszusatz%',
		gettext('Wird durch den Adresszusatz des Empfängers ersetzt'),
	],
	['%bundesland%', gettext('Wird durch das Bundesland des Empfängers ersetzt')],
	['%iban%', gettext('IBAN des Kontos des Empfängers')],
	['%bic%', gettext('BIC des Kontos des Empfängers')],
	[
		'%iban_anonymisiert%',
		gettext(
			'Anonymisierte IBAN des Kontos des Empfängers (DE12 XXXX XXXX XXXX 3456 78)'
		),
	],
	[
		'%bic_anonymisiert%',
		gettext('Anonymisierte BIC des Kontos des Empfängers (ABCXXXXXXDE)'),
	],
	[
		'%saldo%',
		gettext('Das Saldo aller Rechnungen, die zu dieser Adresse gehören'),
	],
	['%guthaben%', gettext('Guthaben des Empfängers')],
	['%abw_kontoinhaber%', gettext('Abweichender Kontoinhaber aus dem Profil')],
	[
		'%mitgliedsbeitrag%',
		gettext(
			'Individueller Mitgliedschaftsbeitrag aus dem Profil. Für die Liste der Gruppenbeiträge kann %mitgliedsbeitraege% verwendet werden'
		),
	],
	[
		'%beitragsintervall%',
		gettext('Intervall der Beitragszahlung (in Monaten) aus dem Profil'),
	],
	[
		'%zahlungsart%',
		gettext(
			'Zahlungsart des Mitglieds aus dem Profil (Überweisung, Lastschrift,...)'
		),
	],
	['%land%', gettext('Wird durch das Land des Empfängers ersetzt')],
	[
		'%strasse_gesch%',
		gettext('Wird durch die geschäftliche Straße des Empfängers ersetzt'),
	],
	[
		'%stadt_gesch%',
		gettext('Wird durch die geschäftliche Stadt des Empfängers ersetzt'),
	],
	[
		'%bundesland_gesch%',
		gettext('Wird durch das geschäftliche Bundesland des Empfängers ersetzt'),
	],
	[
		'%land_gesch%',
		gettext('Wird durch das geschäftliche Land des Empfängers ersetzt'),
	],
	['%alter%', gettext('Wird durch die das Alter des Empfängers ersetzt')],
	['%alter+x%', gettext('Wird durch das Alter + X des Empfängers ersetzt')],
	[
		'%bevorzugte_kommunikation%',
		gettext(
			'Wird durch die bevorzugte Art der Kommunikation des Empfängers ersetzt'
		),
	],
	[
		'%adressgruppenname%',
		gettext('Wird durch die Adressgruppennamen aus dem Profil ersetzt'),
	],
]

// members
const membersPlaceholder = [
	['%mandatsreferenz%', gettext('Referenznummer des SEPA Lastschriftmandats')],
	['%mandatsdatum%', gettext('Datum der Unterschrift des Lastschriftmandats')],
	['%mitgliedsnummer%', gettext('Mitgliedsnummer aus Profil')],
	[
		'%mitgliedsgruppenname%',
		gettext('Name der Mitgliedsgruppen aus dem Profil'),
	],
	[
		'%mitgliedsgruppenname_aktiv%',
		gettext('Name der aktiven Mitgliedsgruppen aus dem Profil'),
	],
	[
		'%mitgliedsgruppenname_inaktiv%',
		gettext('Name der inaktiven Mitgliedsgruppen aus dem Profil'),
	],
	[
		'%jubilaeum%',
		gettext('Wird durch die Anzahl der Jahre seit Mitgliedsbeitritt ersetzt'),
	],
	['%monat_eintritt%', gettext('Der Monat des Eintrittsdatums')],
	['%jahr_eintritt%', gettext('Das Jahr des Eintrittsdatums')],
	[
		'%datum_antragsstellung%',
		gettext(
			'Wird durch das Datum der Antragsstellung über das Online-Aufnahmeformular ersetzt'
		),
	],
	[
		'%mitgliedschaftsantrag_bestaetigt_am%',
		gettext(
			'Wird durch das Datum der Bestätigung des Mitgliedschaftsantrags über das Online-Aufnahmeformular ersetzt'
		),
	],
	[
		'%abteilung%',
		gettext('Alle Abteilungen des Mitglieds (Dachverbandslösung)'),
	],
	[
		'%benutzername%',
		gettext('Wird durch den Benutzernamen / die Login E-Mail ersetzt'),
	],
	[
		'%beitrittsdatum%',
		gettext('Wird durch das Beitrittsdatum des Mitglieds ersetzt'),
	],
	[
		'%austrittsdatum%',
		gettext('Wird durch das Austrittsdatum des Mitglieds ersetzt'),
	],
	[
		'%kuendigungsdatum%',
		gettext('Wird durch das Kündigungsdatum des Mitglieds ersetzt'),
	],
	[
		'%verwaltendes_mitglied%',
		gettext('Wird durch das verwaltende Mitglied des Mitglieds ersetzt'),
	],
	[
		'%verwaltete_mitglieder%',
		gettext(
			'Wird durch die Mitglieder ersetzt, die von dem Mitglied verwaltet werden'
		),
	],
	[
		'%mitgliedsbeitraege%',
		gettext(
			'Wird durch eine Liste aller Gruppenbeiträge ersetzt, für die Beiträge aktiviert sind. Für den individuellen Beitrag kann %mitgliedsbeitrag% verwendet werden'
		),
	],
	[
		'%beginn_erster_leistungszeitraum_monat%',
		gettext('Wird durch den Monat des ersten Leistungszeitraumes ersetzt'),
	],
	[
		'%beginn_erster_leistungszeitraum_jahr%',
		gettext('Wird durch das Jahr des ersten Leistungszeitraumes ersetzt'),
	],
	[
		'%gesamt_jahresbeitrag_brutto%',
		gettext('Wird durch den brutto Jahresgesamtbeitrag ersetzt'),
	],
	[
		'%gesamt_jahresbeitrag_netto%',
		gettext('Wird durch den netto Jahresgesamtbeitrag ersetzt'),
	],
	[
		'%gesamt_jahresbeitrag_steuern%',
		gettext('Wird durch den Steueranteil des Jahresgesamtbeitrags ersetzt'),
	],
	[
		'%individueller_jahresbeitrag_brutto%',
		gettext(
			'Wird durch den brutto Jahresbetrag des individuellen Mitgliedschaftsbeitrags ersetzt'
		),
	],
	[
		'%individueller_jahresbeitrag_netto%',
		gettext(
			'Wird durch den netto Jahresbetrag des individuellen Mitgliedschaftsbeitrags ersetzt'
		),
	],
	[
		'%individueller_jahresbeitrag_steuern%',
		gettext(
			'Wird durch den Steueranteil des Jahresbetrags des individuellen Mitgliedschaftsbeitrags ersetzt'
		),
	],
	[
		'%gruppen_jahresbeitrag_brutto%',
		gettext(
			'Wird durch den brutto Jahresbetrag der Mitgliedschaftsbeiträge ersetzt'
		),
	],
	[
		'%gruppen_jahresbeitrag_netto%',
		gettext(
			'Wird durch den netto Jahresbetrag der Mitgliedschaftsbeiträge ersetzt'
		),
	],
	[
		'%gruppen_jahresbeitrag_steuern%',
		gettext(
			'Wird durch den Steueranteil des Jahresbetrags der Mitgliedschaftsbeiträge ersetzt'
		),
	],
	[
		'%EPC_QR_Code_X%',
		`${gettext(
			"Das 'X' manuell ersetzen durch den gewünschten Betrag, sofern das Bankkonto vollständig angegeben ist."
		)}<small class="text-danger">${gettext(
			'Vor dem verwenden muss in den Einstellungen zu Buchhaltung & Finanzen &raquo; "Rechnungen" das Standardkonto für EPC Codes gewählt sein.'
		)}</small>`,
	],
]

// letter and invoices
const senderPlaceholder = [
	[
		'%signatur%',
		gettext(
			'Wird durch die Signatur aus den persönlichen Einstellungen ersetzt'
		),
	],
	['%sender_anrede%', gettext('Wird durch die Anrede des Senders ersetzt')],
	['%sender_titel%', gettext('Wird durch den Titel des Senders ersetzt')],
	['%sender_vorname%', gettext('Wird durch den Vornamen des Senders ersetzt')],
	[
		'%sender_nachname%',
		gettext('Wird durch den Nachnamen des Senders ersetzt'),
	],
	[
		'%sender_email%',
		gettext('Wird durch die primäre E-Mail Adresse des Senders ersetzt'),
	],
	[
		'%sender_email_priv%',
		gettext('Wird durch die private E-Mail Adresse des Senders ersetzt'),
	],
	[
		'%sender_email_gesch%',
		gettext('Wird durch die geschäftliche E-Mail Adresse des Senders ersetzt'),
	],
	[
		'%sender_telefon_priv%',
		gettext('Wird durch die private Telefonnummer des Senders ersetzt'),
	],
	[
		'%sender_telefon_gesch%',
		gettext('Wird durch die geschäftliche Telefonnummer des Senders ersetzt'),
	],
	[
		'%sender_telefon_mobil%',
		gettext('Wird durch die mobile Telefonnummer des Senders ersetzt'),
	],
]

// invoices
const invoicePlaceholder = [
	[
		'%bezeichnung%',
		gettext('Name des Mitgliedsbeitrags, "Beleg" oder "Rechnung"'),
	],
	['%rechnungsnummer%', gettext('Wird durch die Rechnungsnummer ersetzt')],
	[
		'%rechnungsbetrag%',
		gettext('Wird durch den gesamten Rechnungsbeitrag ersetzt'),
	],
	['%rechnungsdatum%', gettext('Wird durch das Rechnungsdatum ersetzt')],
	[
		'%referenznummer%',
		gettext('Wird durch die Referenznummer der Rechnung ersetzt'),
	],
]

// membership invoices
const replaceFeePlaceholder = [
	[
		'%kalenderwoche%',
		gettext('Kalenderwoche des Leistungszeitraums des jeweiligen Mitglieds'),
	],
	[
		'%quartal%',
		gettext('Quartal des Leistungszeitraums des jeweiligen Mitglieds'),
	],
	[
		'%zeitraum_start%',
		gettext('Startdatum des Leistungszeitraums des jeweiligen Mitglieds'),
	],
	[
		'%zeitraum_start_monat%',
		gettext(
			'Monat des Startdatums des Leistungszeitraums des jeweiligen Mitglieds'
		),
	],
	[
		'%zeitraum_start_jahr%',
		gettext(
			'Jahr des Startdatums des Leistungszeitraums des jeweiligen Mitglieds'
		),
	],
	[
		'%zeitraum_ende%',
		gettext('Enddatum des Leistungszeitraums des jeweiligen Mitglieds'),
	],
	[
		'%zeitraum_ende_monat%',
		gettext(
			'Monat des Enddatums des Leistungszeitraums des jeweiligen Mitglieds'
		),
	],
	[
		'%zeitraum_ende_jahr%',
		gettext(
			'Jahr des Enddatums des Leistungszeitraums des jeweiligen Mitglieds'
		),
	],
	[
		'%fuer_mitgliedsnummer%',
		gettext(
			'Wird durch "für die Mitgliedsnummer %mitgliedsnummer%" ersetzt (wird nicht gedruckt, falls keine Mitgliedsnummer vorhanden)'
		),
	],
	[
		'%zeitraum_ende_jahr+X%',
		gettext(
			'Jahr des Enddatum des Leistungszeitraums des jeweiligen Mitglieds addiert mit'
		) + ' &lt;X&gt;',
	],
	[
		'%zeitraum_ende_jahr-X%',
		gettext(
			'Jahr des Enddatum des Leistungszeitraums des jeweiligen Mitglieds subtrahiert mit'
		) + ' &lt;X&gt;',
	],
	[
		'%zeitraum_start_jahr+X%',
		gettext(
			'Jahr des Startdatums des Leistungszeitraums des jeweiligen Mitglieds addiert mit'
		) + ' &lt;X&gt;',
	],
	[
		'%zeitraum_start_jahr-X%',
		gettext(
			'Jahr des Startdatums des Leistungszeitraums des jeweiligen Mitglieds subtrahiert mit'
		) + ' &lt;X&gt;',
	],
]

// #, ##, ###, ...  Rechnungsnummer. Um die Rechnungnummer mit Nullen aufzufüllen, mehrere Rauten (#) verwenden
const invNumberPlaceholder = [
	['%tag%', gettext('Tag des Rechnungsdatums')],
	['%monat%', gettext('Monat des Rechnungsdatums')],
	['%jahr%', gettext('Jahr des Rechnungsdatums')],
]

// Special placeholders for the e-mail templates in the settings
const sendAccessdataPlaceholders = [
	['%name_admin%', gettext('Wird durch den Namen des Admins ersetzt')],
	['%link_zum_mitgliederbereich%', gettext('Link zum Mitgliederbereich')],
	['%passwort_link%', gettext('Link um Passwort festzulegen')],
]

const resetPasswordPlaceholders = [
	[
		'%zuruecksetzen%',
		gettext('Wird durch einen Link der das Password zurücksetzt ersetzt'),
	],
]

const taskNotificationPlaceholders = [
	['%mitglied%', gettext('Wird durch Mitglied ersetzt')],
	['%ersteller%', gettext('Wird durch den Aufgabenersteller ersetzt')],
	['%aufgabenname%', gettext('Wird durch den Aufgabennamen ersetzt')],
	['%beschreibung%', gettext('Wird durch die Aufgabenbeschreibung ersetzt')],
	['%gruppenname%', gettext('Wird durch den Gruppennnamen ersetzt')],
	['%faelligkeitsdatum%', gettext('Wird durch das Fälligkeitsdatum ersetzt')],
	[
		'%link_aufgabe_kalender%',
		gettext(
			'Wird durch einen Link der die Aufgabe als ICS Datei herunterlädt ersetzt'
		),
	],
]

const boardNotificationPlaceholders = [
	['%titel_beitrag%', gettext('Titel des Beitrags')],
	['%titel_thema%', gettext('Titel des Themas')],
	['%titel_forum%', gettext('Titel des Forum')],
	['%empfänger%', gettext('Name des Empfängers der E-Mail')],
	['%ersteller%', gettext('Name des Erstellers des neuen Eintrags')],
	[
		'%link_thema%',
		gettext('Link zum Thema, in dem sich der neue Beitrag befindet'),
	],
]

const eventNotificationPlaceholders = [
	['%datum%', gettext('Wird durch das Datum der Veranstaltung ersetzt')],
	['%eventname%', gettext('Wird durch den Namen der Veranstaltung ersetzt')],
	[
		'%event_beschreibung%',
		gettext('Wird durch die Beschreibung der Veranstaltung ersetzt'),
	],
	['%event_ort%', gettext('Wird durch den Standort der Veranstaltung ersetzt')],
	[
		'%anmeldestart%',
		gettext(
			'Wird durch den Start des Anmeldezeitraums der Veranstaltung ersetzt'
		),
	],
	[
		'%anmeldeende%',
		gettext(
			'Wird durch das Ende des Anmeldezeitraums der Veranstaltung ersetzt'
		),
	],
	[
		'%anmeldezeitraum%',
		gettext(
			'Wird durch den Anmeldezeitraum ("von ..."/"bis ..."/"... - ...") der Veranstaltung ersetzt'
		),
	],
	[
		'%anmeldezeitraum_en%',
		gettext(
			'Wird durch den Anmeldezeitraum ("from ..."/"until ..."/"... - ...") der Veranstaltung ersetzt'
		),
	],
]

const eventMemberNotifactionPlaceholders = [
	[
		'%link_bestaetigen_oder_absagen%',
		gettext(
			'Wird durch einen Link um auf die Veranstaltung zu reagieren ersetzt'
		),
	],
	[
		'%link_kalendereinladung_ics%',
		gettext(
			'Wird durch einen Link der die Veranstaltung als ICS herunterlädt ersetzt'
		),
	],
	[
		'%tagesordnung%',
		gettext(
			'Wird bei Sitzungseinladungen durch vorhandene Tagesordnungspunkte ersetzt'
		),
	],
]

const votingNotificationPlaceholders = [
	['%abstimmung_name%', gettext('Wird durch den Namen der Abstimmung ersetzt')],
	[
		'%abstimmung_beschreibung%',
		gettext('Wird durch die Beschreibung der Abstimmung ersetzt'),
	],
	['%link_zur_abstimmung%', gettext('Link zur Abstimmung')],
	['%startdatum%', gettext('Wird durch das Startdatum der Abstimmung ersetzt')],
	[
		'%startuhrzeit%',
		gettext('Wird durch die Startuhrzeit der Abstimmung ersetzt'),
	],
	['%enddatum%', gettext('Wird durch das Enddatum der Abstimmung ersetzt')],
	['%enduhrzeit%', gettext('Wird durch die Enduhrzeit der Abstimmung ersetzt')],
]

const customFieldRequestNotificationPlaceholders = [
	['%name_admin%', gettext('Wird durch den Namen des Admin ersetzt')],
	['%name_admins%', gettext('Wird durch den Namen des Admin ersetzt')],
	['%link_zum_mitgliederbereich%', gettext('Link zum Mitgliederbereich')],
	[
		'%datenfeld_name%',
		gettext('Wird durch den Namen des individuellen Mitgliederfeldes ersetzt'),
	],
	[
		'%datenfeld_beschreibung%',
		gettext(
			'Wird durch die Beschreibung des individuellen Mitgliederfeldes ersetzt'
		),
	],
]

const documentTemplatePlaceholders = [
	['%seitennummer%', gettext('Seitennummer')],
	['%seitenanzahl%', gettext('Seitenanzahl')],
]

const mailingPlaceholders = [
	['%betreff%', gettext('Die Betreffzeile des Serienbriefs')],
	['%fließtext%', gettext('Der Fließtext des Serienbriefs')],
]

const painPlaceholders = [
	[
		'%beschreibung_für_leistungszeitraum%',
		gettext(
			'Wird durch die Beschreibung des Leistungszeitraums der Rechnung ersetzt'
		),
	],
	[
		'%fuer_mitgliedsnummer%',
		gettext(
			'Wird durch "für die Mitgliedsnummer %mitgliedsnummer%" ersetzt (wird nicht gedruckt, falls keine Mitgliedsnummer vorhanden)'
		),
	],
	[
		'%für_mitgliedsnummer%',
		gettext(
			'Wird durch "für die Mitgliedsnummer %mitgliedsnummer%" ersetzt (wird nicht gedruckt, falls keine Mitgliedsnummer vorhanden)'
		),
	],
	[
		'%fuer_mitgliedsnummer_en%',
		gettext(
			'Wird durch "for the membership number %mitgliedsnummer%" ersetzt (wird nicht gedruckt, falls keine Mitgliedsnummer vorhanden)'
		),
	],
]

const placeholderLists = () => {
	// customFieldPlaceholder will be searched using the API
	return {
		overallPlaceholder: [gettext('Überall'), overallPlaceholder],
		organizationPlaceholder: [gettext('Verein'), organizationPlaceholder],
		addressPlaceholder: [gettext('Adressen'), addressPlaceholder],
		membersPlaceholder: [gettext('Mitglieder'), membersPlaceholder],
		senderPlaceholder: [gettext('Absender'), senderPlaceholder],
		invoicePlaceholder: [gettext('Rechnungen'), invoicePlaceholder],
		painPlaceholders: [gettext('Lastschriften'), painPlaceholders],
		replaceFeePlaceholder: [
			gettext('Mitgliedsbeiträge'),
			replaceFeePlaceholder,
		],
		invNumberPlaceholder: [gettext('Rechnungsnummern'), invNumberPlaceholder],
		// Special placeholders for e-mail templates in settings:
		sendAccessdataPlaceholders: [
			gettext('Zugangsdaten versenden'),
			sendAccessdataPlaceholders,
		],
		resetPasswordPlaceholders: [
			gettext('Passwort vergessen E-Mail'),
			resetPasswordPlaceholders,
		],
		taskNotificationPlaceholders: [
			gettext('Benachrichtigung für Aufgaben'),
			taskNotificationPlaceholders,
		],
		boardNotificationPlaceholders: [
			gettext('Benachrichtigung für neue Foreneinträge'),
			boardNotificationPlaceholders,
		],
		eventNotificationPlaceholders: [
			gettext('Veranstaltungsinformationen'),
			eventNotificationPlaceholders,
		],
		eventMemberNotifactionPlaceholders: [
			gettext('Veranstaltungsinformationen für Mitgliedergruppen'),
			eventMemberNotifactionPlaceholders,
		],
		votingNotificationPlaceholders: [
			gettext('Einladung zum Voting'),
			votingNotificationPlaceholders,
		],
		customFieldRequestNotificationPlaceholders: [
			gettext('Datenfeldänderungsanfrage'),
			customFieldRequestNotificationPlaceholders,
		],
		documentTemplatePlaceholders: [
			gettext('Dokumentenvorlagen'),
			documentTemplatePlaceholders,
		],
		mailingPlaceholders: [gettext('Serienbrief'), mailingPlaceholders],
	}
}

// These placeholders should not be contained in the placeholder list for system e-mail subjects
export const subjectExclusionFilter = {
	overallPlaceholder: ['%box%', '%logo%', '%logo_r%', '%logo_l%'],
	addressPlaceholder: ['%adressgruppenname%'],
	senderPlaceholder: [],
	membersPlaceholder: [
		'%mitgliedsgruppenname%',
		'%mitgliedsgruppenname_aktiv%',
		'%mitgliedsgruppenname_inaktiv%',
		'%EPC_QR_Code_X%',
	],
	sendAccessdataPlaceholders: [
		'%link_zum_mitgliederbereich%',
		'%passwort_link%',
	],
	taskNotificationPlaceholders: ['%link_aufgabe_kalender%'],
	boardNotificationPlaceholders: ['%link_thema%'],
	eventMemberNotifactionPlaceholders: [
		'%link_bestaetigen_oder_absagen%',
		'%link_kalendereinladung_ics%',
	],
	votingNotificationPlaceholders: ['%link_zur_abstimmung%'],
	customFieldRequestNotificationPlaceholders: ['%link_zum_mitgliederbereich%'],
}

/**
 * Combines the given placeholders
 *
 * @param {string} lists - comma seperated list of placeholder subsets
 * @param {boolean} withLabels - return descriptions too
 * @param {object} exclusionFilters - an object with placeholder lists as keys and a list of placeholders to exclude as value. If the list is empty for a key, then all placeholders belonging to it should be excluded
 * @returns {Array} - An array containing the combined placeholders
 */
export function getCombinedPlaceholderList(
	lists,
	withLabels,
	exclusionFilters = undefined
) {
	const resultList = []

	if (lists === '') {
		return resultList
	}

	const pList = placeholderLists()
	for (const el of lists.split(',')) {
		let list
		if (pList[el] !== undefined) {
			if (exclusionFilters && exclusionFilters[el] !== undefined) {
				list = pList[el][1].filter((pair) => {
					return (
						exclusionFilters[el].length > 0 &&
						!exclusionFilters[el].includes(pair[0])
					)
				})
			} else {
				list = pList[el][1]
			}
		} else {
			list = getContextData(el)
		}
		list.forEach((placeholder) => {
			if (!withLabels) {
				resultList.push(placeholder[0])
			} else {
				resultList.push({ id: placeholder[0], name: placeholder[1] })
			}
		})
	}
	return resultList
}
