// import ClassicEditor from '../components/ckEditorBase'
// import '../../scss/utils/ckeditor.scss'
import { getGlobals } from '../utils/django'
import {
	getCombinedPlaceholderList,
	subjectExclusionFilter,
} from '../utils/placeholders'
import { gettext, interpolate, translateKind } from './translation'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import { Paragraph, ParagraphButtonUI } from '@ckeditor/ckeditor5-paragraph/src'
import { HeadingButtonsUI } from '@ckeditor/ckeditor5-heading/src'
import Mention from '@ckeditor/ckeditor5-mention/src/mention'
import { icons } from '@ckeditor/ckeditor5-core'
import { escapeHtml } from '../utils/html'
import { asyncDebounce, debounce } from '../utils/debounce'
import { delay } from './delays'
import {
	AllowedSettings,
	configurePlugins,
	configureToolbar,
	DefaultPluginConfiguration,
} from '../components/ckEditorConfiguration'
import { hideElement, setElementVisibility } from './helpers/show'
import { get } from '../api/crud'
import { loadMore } from './animation/scroll'

const instances = {}

/**
 * Initializes CKEditor including s placeholder search and auto-suggest
 *
 * @param {string} selector - css selector for the element that should be replaced by the editor
 * @param {boolean} [allowMedia] - allow uploading images
 * @param {boolean} [allowPageBreak] - allow adding a page break
 * @param {boolean} [allowTables] - allow adding tables
 * @param {boolean} [allowAdvancedFontOptions] - allow changing font size and colors
 * @param {boolean} [allowSpecialChars] - allow adding special charts
 * @param {string} allowedPlaceholders - a list of placeholder list names from placeholder component - if name is not in placeholder component this component is looking for a context data with the same name
 * @param {boolean} [showToolbar] - shows the editor toolbar
 * @param {boolean} [allowFontSelection] - allow the selection of non-standard fonts
 * @param {Array<string>} disallowSpecificTools - List of tools to hide from the toolbar (if disallowing certain plugins is not enough). Available tools to hide: ['undo', 'redo', 'heading', 'fontColor', 'fontSize', 'fontFamily', 'highlight', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', 'link', 'bulletedList', 'numberedList', 'outdent', 'indent', 'alignment', 'imageInsert', 'insertTable', 'blockQuote', 'pageBreak', 'horizontalLine', 'specialCharacters']
 * @param {boolean} isAddressHtml - Transforms the editor into a single line and changes header and font size
 * @returns {Promise<ClassicEditor>} Promise of the CKEditor creation
 */
export default async function initCKEditor(
	selector,
	allowMedia = false,
	allowPageBreak = false,
	allowTables = true,
	allowAdvancedFontOptions = false,
	allowSpecialChars = true,
	allowedPlaceholders = '',
	showToolbar = true,
	allowFontSelection = true,
	disallowSpecificTools = [],
	isAddressHtml = false
) {
	const placeholders = getCombinedPlaceholderList(allowedPlaceholders, true)
	// save the placeholders inside the textarea that will be represented with the ckeditor
	document.querySelector(selector).dataset.allowedPlaceholders =
		allowedPlaceholders

	const lang = getGlobals().languageCode

	const config = {
		language: {
			ui: lang,
			content: lang,
		},
		mention: {
			feeds: [
				{
					marker: '%',
					feed: delayGetFeedItems,
					itemRenderer: customItemRenderer,
					minimumCharacters: 0,
				},
			],
		},
		heading: {
			options: isAddressHtml
				? [
						{
							model: 'paragraphTop',
							view: {
								name: 'div',
								classes: [
									'return-address-text-vertical-align-top',
									'p-text-small',
								],
							},
							title: gettext('Text oben ausrichten'),
							icon: icons.alignTop,
							converterPriority: 'high',
						},
						{
							model: 'paragraphBottom',
							view: {
								name: 'div',
								classes: [
									'return-address-text-vertical-align-bottom',
									'p-text-small',
								],
							},
							title: gettext('Text unten ausrichten'),
							icon: icons.alignBottom,
							converterPriority: 'highest',
						},
				  ]
				: [
						{ model: 'paragraph', title: 'Paragraph' },
						{
							model: 'paragraphSmall',
							view: {
								name: 'p',
								classes: 'p-text-small',
							},
							title: gettext('Kleiner Text'),
							class: 'p-text-small',
							converterPriority: 'high',
						},
						{
							model: 'paragraphTiny',
							view: {
								name: 'p',
								classes: 'p-text-tiny',
							},
							title: gettext('Sehr kleiner Text'),
							class: 'p-text-tiny',
							converterPriority: 'high',
						},
						{ model: 'heading1', view: 'h2', title: 'Heading 1' },
						{ model: 'heading2', view: 'h3', title: 'Heading 2' },
						{ model: 'heading3', view: 'h4', title: 'Heading 3' },
				  ],
		},
	}
	if (isAddressHtml) {
		config.fontSize = {
			options: [
				{
					model: 'fontSize',
					view: { name: 'span', classes: 'text-extreme-tiny' },
					title: gettext('Extrem klein'),
				},
				'tiny',
				'small',
				'default',
			],
		}
	}
	if (allowFontSelection) {
		await get('document-template-settings', '{usableFonts}').then(
			(response) => {
				if (response.status === 200) {
					const usableFonts = response.data.results[0].usableFonts
					config.fontFamily = {
						options: usableFonts,
					}
				}
			}
		)
	}

	let toolbar = ['undo', 'redo']
	let plugins = [Essentials]
	if (showToolbar) {
		const allowed = new AllowedSettings({
			allowMedia,
			allowPageBreak,
			allowTables,
			allowAdvancedFontOptions,
			allowSpecialChars,
		})
		toolbar = configureToolbar(
			DefaultPluginConfiguration,
			allowed,
			disallowSpecificTools
		)
		plugins = configurePlugins(DefaultPluginConfiguration, allowed)
		if (allowed.allowMedia) {
			config.image = {
				toolbar: [
					'imageStyle:inline',
					'imageStyle:block',
					'imageStyle:alignLeft',
					'imageStyle:alignRight',
					'imageTextAlternative',
					'linkImage',
				],
			}
		}
		if (allowed.allowTables) {
			config.table = {
				contentToolbar: [
					'tableColumn',
					'tableRow',
					'mergeTableCells',
					'tableCellProperties',
					'tableProperties',
				],
				tableProperties: {
					defaultProperties: {
						borderColor: 'black',
						borderWidth: '1px',
						borderStyle: 'solid',
					},
				},
				tableCellProperties: {
					defaultProperties: {
						borderColor: 'black',
						borderWidth: '1px',
						borderStyle: 'solid',
					},
				},
			}
		}
	}
	plugins.push(Mention) // Always use the plugin for placeholders - it isn't shown in the toolbar
	plugins.push(Paragraph) // If no heading options are available and this is missing you can't type.
	if (isAddressHtml) {
		plugins.push(HeadingButtonsUI)
		plugins.push(ParagraphButtonUI)
		toolbar.push('paragraphTop')
		toolbar.push('paragraphBottom')
	}
	config.plugins = plugins
	config.toolbar = { items: toolbar }
	config.link = {
		defaultProtocol: 'https://',
		decorators: {
			isExternal: {
				mode: 'manual',
				label: gettext('Link in neuem Fenster öffnen'),
				defaultValue: false,
				attributes: {
					target: '_blank',
					rel: 'noopener noreferrer',
				},
			},
		},
	}

	try {
		instances[selector] = await ClassicEditor.create(
			document.querySelector(selector),
			config
		)
		if (!showToolbar) {
			const toolbarElement = instances[selector].ui.view.toolbar.element
			hideElement(toolbarElement)
		}
	} catch (error) {
		console.error(error)
	}

	if (placeholders.length > 0) {
		const id = selector.replace('#', '')
		const textareaContainer = document.querySelector(selector).parentElement
		if (textareaContainer) {
			textareaContainer.insertAdjacentHTML(
				'beforeend',
				buildPlaceholdersElement(id)
			)
		}
		const accordionId = `accordion_${id}`
		const accordionBodySelector = `#${accordionId} .accordion-body`
		const body = document.querySelector(accordionBodySelector)
		expandPlaceholderList(instances[selector], body, placeholders)
	}

	const subjectSelector = `${selector}Subject`
	const subjectElement = document.querySelector(subjectSelector)
	if (subjectElement) {
		const subjectPlaceholders = getCombinedPlaceholderList(
			allowedPlaceholders,
			true,
			subjectExclusionFilter
		)
		if (subjectPlaceholders.length > 0) {
			document.querySelector(subjectSelector).dataset.allowedPlaceholders =
				allowedPlaceholders
			const id = subjectSelector.replace('#', '')
			const textareaContainer =
				document.querySelector(subjectSelector).parentElement
			if (textareaContainer) {
				textareaContainer.insertAdjacentHTML(
					'beforeend',
					buildPlaceholdersElement(id)
				)
			}
			const accordionId = `accordion_${id}`
			const accordionBodySelector = `#${accordionId} .accordion-body`
			const body = document.querySelector(accordionBodySelector)
			const subjectField = document.querySelector(subjectSelector)
			expandPlaceholderList(subjectField, body, subjectPlaceholders)
		}
	}
	if (isAddressHtml) {
		setEditorToSingleLine(instances[selector], selector)
	}

	return instances[selector]
}

/**
 * Delays the getFeedItems call to save api requests
 *
 * @param {string} queryText - The text after the maker to search for
 * @returns {Function} debounced function
 */
async function delayGetFeedItems(queryText) {
	return await asyncDebounce(() => getFeedItems(queryText), delay.default)()
}

/**
 * Query the hardcoded placeholders  and the customfield placeholders if needed
 *
 * @param {string} queryText - The text after the maker to search for
 * @returns {undefined|Array} - Either undefined if something fails, else an Array of the placeholders matching the queryText
 */
async function getFeedItems(queryText) {
	queryText = queryText.toLowerCase()
	const allowedPlaceholders = getCurrentPlaceholders()
	if (!allowedPlaceholders) return

	let placeholders = getCombinedPlaceholderList(allowedPlaceholders, true)
	placeholders = placeholders.filter((possibleTerm) =>
		possibleTerm.id.toLowerCase().includes(queryText)
	)
	placeholders = placeholders.slice(0, 10)
	if (allowedPlaceholders.includes('customFieldPlaceholder')) {
		const response = await get('custom-field', '{placeHolder,name,kind}', {
			search: queryText,
			deleted: false,
		})
		const customPlaceholders = []
		for (const customField of response.data.results) {
			const name = interpolate(
				gettext('Wird durch das individuelle %(kind)s "%(name)s" ersetzt'),
				{ kind: translateKind(customField.kind), name: customField.name }
			)
			customPlaceholders.push({ id: customField.placeHolder, name: name })
		}
		placeholders = placeholders.concat(customPlaceholders)
	}
	return placeholders
}

/**
 * Thanks to the well coded ckeditor we cannot get the ckeditor object or the allowedPlaceholders
 * in the getFeedItems so we have to get it manually
 *
 * @returns {string|undefined} - Either a string containing the allowed placeholders or undefined
 */
function getCurrentPlaceholders() {
	const element = document.activeElement
	if (element.classList.contains('ck-content')) {
		const textarea = element.parentNode.parentNode.previousSibling
		if (textarea.nodeName.toLowerCase() === 'textarea') {
			return textarea.dataset.allowedPlaceholders
		}
	}
}

/**
 * Generates the palceholder list container accordion
 *
 * @param {string} id - id of the editor without the `#`
 * @returns {string} template string of the accordion with empty body
 */
function buildPlaceholdersElement(id) {
	return `
		<div class="accordion accordion-flush" id="accordion_${id}">
			<div class="accordion-item">
				<h2 class="accordion-header" id="accordionHeading_${id}">
					<button class="accordion-button collapsed border" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_${id}" aria-expanded="false" aria-controls="collapse_${id}">
						<i class="far fa-percent"></i>&nbsp;${gettext('Platzhalter anzeigen')}
					</button>
				</h2>
				<div id="collapse_${id}" class="accordion-collapse collapse" aria-labelledby="accordionHeading_${id}" data-bs-parent="#accordion_${id}">
					<div class="accordion-body">
					</div>
				</div>
			</div>
		</div>
	`
}
/**
 * Show or hide placeholderlist
 *
 * @param {ClassicEditor} editor - CKEditor instance
 * @param {Element} accordionBody - Bootstrap accordion body
 * @param {Array} placeholders - list of placeholders
 */
function expandPlaceholderList(editor, accordionBody, placeholders) {
	const id = Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '')
	const label = gettext('Platzhalter suchen')

	const rows = placeholders.map((el) => {
		const idn = `${id}_${el.id.replace(/[%_+]/g, '')}`
		return `<tr><td id="${idn}" data-iscustomfield="false" class="cursor-pointer usePlaceholder">${escapeHtml(
			el.id
		)}</td><td>${escapeHtml(el.name)}</td></tr>`
	})

	let table = `
		<table class="table placeholderList">
			<thead>
				<tr><th>${gettext('Platzhalter')}</th><th>${gettext('Beschreibung')}</tr>
			</thead>
			<tbody>
				${rows.join('\n')}
			</tbody>
		</table>
	`

	const searchInput = `
		<div class="form-floating">
			<input type="text" class="form-control" id="${id}" placeholder="${label}">
			<label for="${id}"><i class="far fa-search"></i>&nbsp;${label}</label>
		</div>
		<div class="placeholderTableParent mt-2">${table}</div>
	`

	accordionBody.innerHTML = searchInput
	table = accordionBody.querySelector('.placeholderList')
	const selector = `#${id}`
	document.querySelector(selector).addEventListener(
		'keyup',
		debounce(() => {
			searchPlaceholder(document.querySelector(selector), table, editor)
		}, delay.default)
	)

	table.querySelectorAll(`.usePlaceholder`).forEach((element) => {
		element.addEventListener('click', (event) => usePlaceholder(event, editor))
	})

	const prepareSearch = (event) => {
		searchCustomFieldPlaceholders(
			document.querySelector(selector).value,
			table,
			editor,
			Number.parseInt(table.dataset.page) || 1
		)
	}
	accordionBody
		.querySelector('.placeholderTableParent')
		.addEventListener('scroll', (event) => loadMore(event, prepareSearch))
	searchCustomFieldPlaceholders('', table, editor)
}

/**
 * Sets the clicked placeholder in the ckeditor
 *
 * @param {Event} event - Default JS onclicke event object
 * @param {object} editor - The ckeditor object
 */
function usePlaceholder(event, editor) {
	const element = event.currentTarget
	if (editor.tagName === 'INPUT') {
		let startPos = editor.selectionStart
		let endPos = editor.selectionEnd
		const lastCursorPosition = editor.dataset.cursorPosition
		if (!(startPos === 0 && endPos === 0)) {
			if (startPos === endPos && startPos !== lastCursorPosition) {
				startPos = lastCursorPosition
				endPos = lastCursorPosition
			}
			editor.value = `${editor.value.substring(0, startPos)}${
				element.innerHTML
			}${editor.value.substring(endPos, editor.value.length)}`
		} else {
			editor.value = `${editor.value}${element.innerHTML}`
		}
		editor.dataset.cursorPosition =
			parseInt(editor.dataset.cursorPosition) + element.innerHTML.length
	} else {
		editor.execute('mention', { marker: '%', mention: element.innerHTML })
	}
}

/**
 * Search for a placeholder, triggered while typing
 *
 * @param {Element} input - search input field
 * @param {Element} table - placeholder table
 * @param {object} editor - The ckeditor object
 */
function searchPlaceholder(input, table, editor) {
	table.parentElement.scrollTo(0, 0)
	let txtValue = ''
	const filter = input.value.toUpperCase()
	// Loop through all table rows, and hide those who don't match the search query
	for (const tr of table.getElementsByTagName('tr')) {
		for (const td of tr.getElementsByTagName('td')) {
			txtValue = td.textContent || td.innerText
			if (txtValue.toUpperCase().indexOf(filter) > -1 || filter === '') {
				setElementVisibility(tr, true)
				break
			} else {
				setElementVisibility(tr, false)
			}
		}
	}

	searchCustomFieldPlaceholders(filter, table, editor, 1, true)
}

/**
 * Searches the custom field placeholders and displays the results in the placeholder table
 *
 * @param {string} filter - The searchterm
 * @param {Element} table - The table element to change the body for
 * @param {object} editor - The ckeditor object
 * @param {number|undefined} page - (optional) The page of the search
 * @param {boolean|undefined} reset - (optional) Resets the custom fields that are displayed in the table
 * @returns {undefined} Nothing
 */
async function searchCustomFieldPlaceholders(
	filter,
	table,
	editor,
	page = 1,
	reset = false
) {
	let allowedPlaceholders = editor.sourceElement?.dataset.allowedPlaceholders
	if (!allowedPlaceholders)
		allowedPlaceholders = editor.dataset.allowedPlaceholders
	if (
		!allowedPlaceholders ||
		!allowedPlaceholders.includes('customFieldPlaceholder')
	)
		return

	const customFieldsPlaceholders = await get(
		'custom-field',
		'{id,name,kind,placeHolder}',
		{ search: filter, deleted: false },
		5,
		page,
		undefined,
		false
	)
	if (customFieldsPlaceholders.status !== 200) return
	let customFields = ''
	for (const customField of customFieldsPlaceholders.data.results) {
		const name = interpolate(
			gettext('Wird durch das individuelle %(kind)s "%(name)s" ersetzt'),
			{ kind: translateKind(customField.kind), name: customField.name }
		)
		customFields += `<tr><td id="${
			customField.id
		}" data-iscustomfield="true" class="cursor-pointer usePlaceholder">${escapeHtml(
			customField.placeHolder
		)}</td><td>${escapeHtml(name)}</td></tr>`
	}
	const tbody = table.querySelector('tbody')
	if (reset) {
		// its a search - remove all customfield entries
		tbody.querySelectorAll('td[data-iscustomfield="true"]').forEach((td) => {
			td.parentElement.remove()
		})
	}
	tbody.innerHTML += customFields
	table.dataset.page = page + 1

	tbody.querySelectorAll('.usePlaceholder').forEach((element) => {
		element.addEventListener('click', (event) => usePlaceholder(event, editor))
	})
}

/**
 * Render placeholders
 *
 * @param {object} item - placeholder item object
 * @returns {HTMLDivElement} rendered placeholder element
 */
function customItemRenderer(item) {
	const itemElement = document.createElement('div')

	itemElement.classList.add('custom-item')
	itemElement.textContent = `${item.id} `

	const usernameElement = document.createElement('span')

	usernameElement.classList.add('custom-item-username')
	usernameElement.textContent = item.name

	itemElement.appendChild(usernameElement)

	return itemElement
}

/**
 * Adds CKEditor on-change eventlisteners to correctly mark data as changed.
 *
 * @param {object} ckeditors - A object containing all ckeditors and their ids
 */
export function setCkEditorListeners(ckeditors) {
	Object.keys(ckeditors).forEach((textAreaId) => {
		const editor = ckeditors[textAreaId]
		document
			.querySelector(`#${textAreaId} ~ .ck-editor [role="textbox"]`)
			.addEventListener('keyup', (event) => {
				if (event.key === '%' || event.keyCode === 53) {
					applyMentionOnBlankPlaceholders(editor, textAreaId)
				}
			})
		editor.model.document.on('change:data', () => {
			changeTextAreaInput(editor, textAreaId)
		})
	})
}

/**
 * Change the value of the textarea of the CkEditor to the data of the CkEditor and trigger the change event of the textarea
 *
 * @param {object} editor - The CkEditor object
 * @param {string} textAreaId - The textarea id of the CkEditor
 */
export function changeTextAreaInput(editor, textAreaId) {
	const textArea = document.querySelector(`#${textAreaId}`)
	const change = new Event('change')

	textArea.value = editor.getData()
	textArea.dispatchEvent(change)
}

/**
 * Returns the editor by the same selector it is initialized with
 *
 * @param {string} selector - editor textarea selector
 * @returns {ClassicEditor} CKEditor instance
 */
export function getEditorBySelector(selector) {
	return instances[selector]
}

/**
 * Disables CKEditor editing by setting the 'contenteditable' attribute 'false'
 *
 * @param {object} editor - The CkEditor object
 */
export function disableEditorEditing(editor) {
	editor.setAttribute('contenteditable', false)
}

/**
 * Enables CKEditor editing by setting the 'contenteditable' attribute 'true'
 *
 * @param {object} editor - The CkEditor object
 */
export function enableEditorEditing(editor) {
	editor.setAttribute('contenteditable', true)
}

/**
 * Disables (shift+)enter in ckeditor and adds a class that changes the ckeditor visually to simulate single line input
 *
 * @param {object} editor - The CkEditor object
 * @param {string} selector - editor textarea selector
 */
function setEditorToSingleLine(editor, selector) {
	selector = selector.includes('#') ? selector : `#${selector}`
	editor.keystrokes.set('Enter', (event, cancel) => {
		cancel()
	})
	editor.keystrokes.set('Shift+Enter', (event, cancel) => {
		cancel()
	})
	document
		.querySelector(`${selector} ~ .ck-editor .ck.ck-editor__main`)
		.classList.add('ck-editor__single-line')
}

/**
 * Applies "Mention" on any blank placeholder in the ckeditor
 *
 * @param {object} editor - The CkEditor object
 * @param {Element} textarea - textarea element from the ckEditor
 */
export function applyMentionOnBlankPlaceholders(editor, textarea) {
	const allowedPlaceholders = document.querySelector(`#${textarea}`).dataset
		.allowedPlaceholders
	const placeholders = getCombinedPlaceholderList(allowedPlaceholders, false)

	let editorData = editor.getData()
	const lastCursorPosition = editor.model.document.selection.getFirstPosition()

	const regex = /%\w+%(?!["])/g
	const allValidPlaceholdersInEditorData = [
		...new Set(editorData.match(regex)),
	].filter((element) => placeholders.includes(element))

	allValidPlaceholdersInEditorData.forEach((placeholder) => {
		editorData = editorData.replaceAll(
			`<span class="mention" data-mention="${placeholder}">${placeholder}</span>`,
			placeholder
		)
		editorData = editorData.replaceAll(
			placeholder,
			`<span class="mention" data-mention="${placeholder}">${placeholder}</span>`
		)
	})

	editor.setData(editorData)
	editor.model.change((writer) => {
		writer.setSelection(writer.createPositionAt(lastCursorPosition))
	})
}
